/* eslint-disable camelcase */

import { api } from '@/services/api'

export type GalleryAlbum = {
  id: string
  title: string
  cover_photo: string
  photos: GalleryAlbumItem[]
}

export type GalleryAlbumItem = {
  id: number
  photo: string
  mobile_photo: string
  alt: string
}

export function getGalleryAlbums() {
  return api.get<GalleryAlbum[]>(
    '/albums/',
    {},
    {},
    {
      i18n: true,
    },
  )
}
