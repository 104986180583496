/**
 * @description The LangSwitcher component.
 */
import React, { useMemo, useState } from 'react'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { useCurrentPage } from '@/hooks/useCurrentPage'
import { Link } from 'react-router-dom'
import { appConf } from '@/config'
import { makeMods } from '@creatorum/bem'
import './LangSwitcher.scss'
import { rrh } from '@/services/rrh'

export const LangSwitcher: React.FC = function (props) {
  const locale = useLocale()
  const { page, matches } = useCurrentPage()
  const [isOpen, setIsOpen] = useState(false)
  const currentLocale = useMemo(
    () => appConf.ml.locales.find((l) => l === locale),
    [locale],
  )
  const restLocales = useMemo(
    () => appConf.ml.locales.filter((l) => l !== locale),
    [locale],
  )
  const pagePath = page?.path || ''

  return (
    <div
      className={makeMods('lang', {
        open: isOpen,
      })}
    >
      <div className="lang__cont">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="lang__current"
        >
          {currentLocale}
        </button>
        <div className="lang__list">
          {restLocales.map((l) => (
            <div className="lang__list-el" key={l}>
              <Link
                onClick={() => setIsOpen(false)}
                to={rrh.generatePath(pagePath, { ...matches, locale: l })}
              >
                {l}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
