import { useState, useEffect, useMemo, useCallback } from 'react'

function getRootFontSize() {
  const html = document.querySelector('html')
  return window.getComputedStyle(html as HTMLHtmlElement).fontSize
}

export function useRem(rootFZ = 18) {
  const [fz, setFZ] = useState(getRootFontSize())

  useEffect(() => {
    const handle = () => {
      setFZ(getRootFontSize())
    }
    window.addEventListener('resize', handle)
    return () => window.removeEventListener('resize', handle)
  }, [])

  const currentFzInPx = useMemo(() => parseFloat(fz), [fz])

  const currentRatio = useMemo(
    () => currentFzInPx / rootFZ,
    [currentFzInPx, rootFZ],
  )

  const rem = useCallback(
    (targetFZ: number, useRound = false) => {
      let result = targetFZ * currentRatio
      if (useRound) result = Math.round(result)
      return result
    },
    [currentRatio],
  )

  return {
    rem,
    ratio: currentRatio,
    rootFZ: currentFzInPx,
  }
}
