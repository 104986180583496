/**
 * @description The InfoContent component.
 */
import React from 'react'
import './InfoContent.scss'

type Props = {
  content: string
}

export const InfoContent: React.FC<Props> = function (props) {
  return (
    <div className="info-content">
      <div
        className="info-content__cont content-area"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </div>
  )
}
