/**
 * @description The DocumentsPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useHowToBuy } from '@/features/posts/hooks/useHowToBuy'
import { ss } from '@/features/posts/stringSource'
import { HowToBuy } from '@/features/posts/components/HowToBuy'
import type { HowToBuy as Data } from '@/features/posts/api/HowToBuyAPI'

export const HowToBuyPage: React.FC = function (props) {
  const howToBuy = useHowToBuy()

  // todo add the bg
  return (
    <PageLayout
      title={ss.posts.howToBuy.title}
      isLoading={howToBuy.isLoading}
      isError={howToBuy.isError}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <HowToBuy data={howToBuy.data as Data} />
    </PageLayout>
  )
}
