/**
 * @description The ConstructionPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useConstructionList } from '@/features/posts/hooks/useConstructionList'
import { ConstructionList } from '@/features/posts/components/ConstructionList'
import { ss } from '@/features/posts/stringSource'
import { useServerSettings } from '@/hooks/useServerSettings'

export const ConstructionPage: React.FC = function (props) {
  const items = useConstructionList()
  const settings = useServerSettings()

  // todo add background
  return (
    <PageLayout
      title={ss.posts.construction.title}
      isLoading={items.isLoading || settings.isLoading}
      isError={items.isError || !!settings.error}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <ConstructionList
        items={items.data || []}
        progress={settings.buildProgress || 0}
        progress2={settings.buildProgress2 || 0}
      />
    </PageLayout>
  )
}
