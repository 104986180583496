import { Config } from '@creatorum/react-router-helper/dist/route'
import { featureConf } from './config'

const NAMESPACE = featureConf.name

export const keys = {
  docs: `${NAMESPACE}/docs`,
  htb: `${NAMESPACE}/htb`,
  news: `${NAMESPACE}/news`,
  newsItem: `${NAMESPACE}/newsItem`,
  construction: `${NAMESPACE}/construction`,
  constructionItem: `${NAMESPACE}/constructionItem`,
  gallery: `${NAMESPACE}/gallery`,
}

export const routes = [
  new Config({
    id: keys.docs,
    path: '/documents',
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.htb,
    path: '/how-to-buy',
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.news,
    path: '/news',
    exact: true,
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.newsItem,
    path: '/news/:slug',
    exact: true,
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.construction,
    path: '/construction',
    exact: true,
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.constructionItem,
    path: '/construction/:slug',
    exact: true,
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.gallery,
    path: '/gallery',
    exact: true,
    mods: ['header-no-phone', 'header-no-flats'],
  }),
]
