import { useMemo } from 'react'
import { ss } from '@/stringSources'
import { windowPanoConf } from '@/features/info/windowPanoConf'

export type MenuItem = {
  key: string
  titleSrc: string
  as?: 'routerLink' | 'link' | 'button'
  to?: string
  onClick?: () => void
}

export type MenuSection = {
  mainItem: MenuItem
  subItems?: MenuItem[]
}

export function useMenuStructure(): MenuSection[] {
  return useMemo(
    // todo add real url
    () => [
      {
        mainItem: {
          key: 'about',
          titleSrc: ss.shared.menu.about.main,
          to: '/',
        },
        subItems: [
          {
            key: 'location',
            titleSrc: ss.shared.menu.about.sub.location,
            to: '/placement',
          },
          {
            key: 'chars',
            titleSrc: ss.shared.menu.about.sub.chars,
            to: '/characteristics',
          },
          {
            key: 'team',
            titleSrc: ss.shared.menu.about.sub.team,
            to: '/team',
          },
        ],
      },
      {
        mainItem: {
          key: 'apartments',
          titleSrc: ss.shared.menu.apartments.main,
          to: '/apartments',
        },
        subItems: [
          {
            key: 'apartmentsVisual',
            titleSrc: ss.shared.menu.apartments.sub.visual,
            to: '/apartments',
          },
          // {
          //   key: 'search',
          //   titleSrc: ss.shared.menu.apartments.sub.search,
          //   to: '/',
          // },
        ],
      },
      {
        mainItem: {
          key: 'media',
          titleSrc: ss.shared.menu.media.main,
          to: '/',
        },
        subItems: [
          {
            key: 'tour',
            titleSrc: ss.shared.menu.media.sub.tour,
            to: '/tour?start=1',
          },
          {
            key: 'pano',
            titleSrc: ss.shared.menu.media.sub.pano,
            to: `/window/${windowPanoConf[windowPanoConf.length - 1].floor}`,
          },
          {
            key: 'gallery',
            titleSrc: ss.shared.menu.media.sub.gallery,
            to: '/gallery',
          },
        ],
      },
      {
        mainItem: {
          key: 'construction',
          titleSrc: ss.shared.menu.construction,
          to: '/construction',
        },
      },
      {
        mainItem: {
          key: 'howToBuy',
          titleSrc: ss.shared.menu.howToBuy,
          to: '/how-to-buy',
        },
      },
      {
        mainItem: {
          key: 'docs',
          titleSrc: ss.shared.menu.docs,
          to: '/documents',
        },
      },
      {
        mainItem: {
          key: 'info',
          titleSrc: ss.shared.menu.info,
          to: '/info',
        },
      },
      {
        mainItem: {
          key: 'news',
          titleSrc: ss.shared.menu.news,
          to: '/news',
        },
      },
      {
        mainItem: {
          key: 'contact',
          titleSrc: ss.shared.menu.contact,
          to: '/contacts',
        },
      },
    ],
    [],
  )
}
