/**
 * @description The TourPage component.
 */
import React, { useEffect, useRef } from 'react'
import { usePanorama } from '@/hooks/usePanorama'
import { appConf } from '@/config'
import { Tour } from '@/features/info/components/Tour'
import { PageLayout } from '@/components/PageLayout'
import { Maybe } from '@/types'
import { useSearchParams } from '@/hooks/useSearchParams'

export const TourPage: React.FC = function (props) {
  const rendererRef = useRef<Maybe<HTMLDivElement>>(null)
  const searchParams = useSearchParams()
  const startNode = searchParams.get('start')

  const { loaded, goToScene } = usePanorama({
    element: rendererRef,
    panoramaURL: `${appConf.panoramsURL}/main/pano.xml`,
    addSkin: true,
  })

  useEffect(() => {
    if (startNode && loaded) {
      goToScene(`node${startNode}`)
    }
  }, [goToScene, startNode, loaded])

  return (
    <PageLayout
      isLoadingBlocking={false}
      isLoading={!loaded}
      fullpage={true}
      footer={false}
    >
      <Tour rendererRef={rendererRef} />
    </PageLayout>
  )
}
