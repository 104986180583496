/**
 * @description The ConstructionListEl component.
 */
import React from 'react'
import { ConstructionListItem } from '@/features/posts/api/ConstructionAPI'
import { useIsMobile } from '@/hooks/useIsMobile'
import { Link } from '@creatorum/react-ml/dist/components/Link'

/* eslint-disable camelcase */

type Props = {
  item: ConstructionListItem
}

export const ConstructionListEl: React.FC<Props> = function (props) {
  const {
    item: { slug, photo, mobile_photo, title },
  } = props
  const isMobile = useIsMobile()

  return (
    <Link to={`/construction/${slug}`} className="cd-list-el">
      <div className="cd-list-el__cont">
        <div className="cd-list-el__bg">
          <img src={isMobile ? mobile_photo || photo : photo} alt={title} />
        </div>

        <div className="cd-list-el__fg">
          <h2 className="cd-list-el__title">{title}</h2>
        </div>
      </div>
    </Link>
  )
}
