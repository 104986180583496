import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { QueryClientProvider } from 'react-query'
import { StringStorageProvider } from '@creatorum/react-ml/dist/stringStorage/StringStorageContext'
import { App } from './App'
import { ConnectedRouter } from 'connected-react-router'
import { fix100vh } from '@creatorum/ff/dist/viewport/fix100vh'
import store from './services/store'
import history from './services/history'
import queryClient from './services/queryClient'
import stringStorage from './services/stringStorage'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import './styles/main.scss'

fix100vh()

const AppWrapper = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryClientProvider client={queryClient}>
        <StringStorageProvider value={stringStorage}>
          <Suspense fallback={() => 'Loading...'}>
            <App />
          </Suspense>
        </StringStorageProvider>
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>
)

export default AppWrapper
