/**
 * @module ducks/example.duck
 * @description The example duck.
 */

import { SimpleDuck } from '@creatorum/react-duck/dist/core/duckStack'
import { StoreState } from '@creatorum/react-duck/dist/core/reduxStack'
import * as StateExtension from '@creatorum/react-duck/dist/StateExtension/StateExtension'
import { all, put, takeEvery, call } from 'redux-saga/effects'
import { featureConf } from '../config'

/** Duck namespace */
const NAMESPACE = `${featureConf.name}/example`

/** Use state extension */
const stateExt = new StateExtension.StateExtensionBuilder(NAMESPACE).make()

/**
 * Action types
 */
export type ActionType = typeof stateExt.actionTypes

/**
 * Actions
 */
export type Action = StateExtension.ExtAction

/**
 * State
 */
interface State {
  some: boolean
}

const initialState: State = {
  some: false,
}

/**
 * Reducer
 */
function reducer(state = initialState, action: Action) {
  switch (action.type) {
    default:
      return stateExt.reducer(state, action) as State
  }
}

/**
 * Action creators
 */
export const { set } = stateExt.actionCreators

/**
 * Selectors
 */
export const { selectField } = stateExt.selectors

/**
 * Sagas
 */
function* someSaga() {
  yield 123
}

/**
 * Object to connect
 */
export const connectable: SimpleDuck = {
  namespace: NAMESPACE,
  reducer,
  sagas: [
    function* () {
      yield all([])
    },
  ],
}
export default connectable
