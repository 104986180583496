/**
 * @description The ConstructionList component.
 */
import React from 'react'
import { ConstructionListItem } from '@/features/posts/api/ConstructionAPI'
import './ConstructionList.scss'
import { ConstructionListProgress } from './ConstructionListProgress'
import { ConstructionListTimeline } from './ConstructionListTimeline'
import { ConstructionListEl } from './ConstructionListEl'
import Sticky from 'react-stickynode'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useRem } from '@/hooks/useRem'

type Props = {
  items: ConstructionListItem[]
  progress: number
  progress2: number
}

export const ConstructionList: React.FC<Props> = function (props) {
  const { items, progress, progress2 } = props

  const isMobile = useIsMobile()
  const { rem } = useRem()

  return (
    <div className="cd">
      <div className="cd__cont">
        <div className="cd__progress">
          <Sticky top={rem(150)} enabled={!isMobile}>
            <ConstructionListProgress
              progress={progress}
              progress2={progress2}
            />
          </Sticky>
        </div>
        <div className="cd__main">
          <div className="cd__list">
            {items.map((el) => (
              <ConstructionListEl item={el} key={el.id} />
            ))}
          </div>
          <div className="cd__timeline">
            <ConstructionListTimeline items={items} />
          </div>
        </div>
      </div>
    </div>
  )
}
