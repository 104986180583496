import React from 'react'
import './PromoPanel.scss'
import eHouseIcon from './i/e-house.png'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/flats/stringSource'
import { useLocaleString } from '@creatorum/react-ml/dist/hooks/useLocaleString'
import { useIsMobile } from '@/hooks/useIsMobile'

export const PromoPanel: React.FC = () => {
  const linkUrl = useLocaleString(ss.flats.apartments.promoPanel.link)

  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <a
        href={linkUrl}
        target="_blank"
        rel="noreferrer"
        className="m-apartments-promo-panel"
      >
        <div className="m-apartments-promo-panel__left">
          <div className="m-apartments-promo-panel__title">
            <T p={ss.flats.apartments.promoPanel.title} />
          </div>
          <div className="m-apartments-promo-panel__icon">
            <img src={eHouseIcon} alt="" />
          </div>
        </div>
        <div className="m-apartments-promo-panel__right">
          <div className="m-apartments-promo-panel__button">
            <T p={ss.flats.apartments.promoPanel.button} />
            <i>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  transform="matrix(-1 0 0 1 36 0)"
                  fill="#A03033"
                />
                <path
                  d="M12.4 18L23.6 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 12.3996L23.6 17.9996L18 23.5996"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </i>
          </div>
        </div>
      </a>
    )
  }

  return (
    <a
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      className="apartments-promo-panel"
    >
      <div className="apartments-promo-panel__title">
        <T p={ss.flats.apartments.promoPanel.title} />
      </div>
      <div className="apartments-promo-panel__button">
        <T p={ss.flats.apartments.promoPanel.button} />
        <i>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              r="18"
              transform="matrix(-1 0 0 1 36 0)"
              fill="#A03033"
            />
            <path
              d="M12.4 18L23.6 18"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18 12.3996L23.6 17.9996L18 23.5996"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      </div>
      <div className="apartments-promo-panel__icon">
        <img src={eHouseIcon} alt="" />
      </div>
    </a>
  )
}
