import { api } from '@/services/api'
import { appConf } from '@/config'
import { id } from '@/utils/mockDataFields'

export interface FeedbackParams {
  name?: string
  email?: string
  phone?: string
  message?: string
  flatID?: number
}

function post(
  params: FeedbackParams & {
    form: 'feedback' | 'callback' | 'bookingOrder'
  },
) {
  if (localStorage.getItem('marshall_contact_anonymous_user_uuid') === null) {
    localStorage.setItem('marshall_contact_anonymous_user_uuid', id())
  }
  const { form, email, message, name, phone, flatID } = params
  const body = {
    form_title: form,
    field_email: email,
    label_field_email: email && 'Email',
    field_message: message,
    label_field_message: message && 'Message',
    field_name: name,
    label_field_name: name && 'Name',
    field_phone: phone,
    label_field_phone: phone && 'Phone',
    field_flat: flatID,
    label_field_flat: flatID && 'Flat ID',
  }
  return api.post(
    '/send_form/',
    body,
    {},
    {
      baseURL: appConf.api.siteURL,
      headers: {
        'X-UUID': localStorage.getItem('marshall_contact_anonymous_user_uuid'),
      },
    },
  )
}

export function postFeedback(params: FeedbackParams) {
  return post({
    ...params,
    form: 'feedback',
  })
}

export function postCallback(params: FeedbackParams) {
  return post({
    ...params,
    form: 'callback',
  })
}

export function postBookingOrder(params: FeedbackParams) {
  return post({
    ...params,
    form: 'bookingOrder',
  })
}
