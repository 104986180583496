/**
 * @description The ConstructionListProgress component.
 */
import React, { useEffect, useState } from 'react'
import progressBg from './i/progress-bg.png'

type Props = {
  progress: number
  progress2: number
}

export const ConstructionListProgress: React.FC<Props> = function (props) {
  const { progress, progress2 } = props
  const [p, setP] = useState(0)
  const [p2, setP2] = useState(0)

  useEffect(() => {
    let c = 0
    const t = setInterval(() => {
      c += 1
      if (c <= progress) {
        setP(c)
      }
      if (c >= progress) {
        clearInterval(t)
      }
    }, 25)
    return () => {
      clearInterval(t)
    }
  }, [progress])

  useEffect(() => {
    let c = 0
    const t = setInterval(() => {
      c += 1
      if (c <= progress2) {
        setP2(c)
      }
      if (c >= progress2) {
        clearInterval(t)
      }
    }, 25)
    return () => {
      clearInterval(t)
    }
  }, [progress2])

  return (
    <div className="cd-progress">
      <div className="cd-progress__cont">
        <div className="cd-progress__bg">
          <svg
            width="420"
            height="471"
            viewBox="0 0 420 471"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <clipPath id="cdp-clip-small">
                <rect
                  id="cdp-clip-small"
                  x={0}
                  width={113}
                  height={((440.5 - 188.5) * p) / 100}
                  y={440.5 - ((440.5 - 188.5) * p) / 100}
                  fill="green"
                />
              </clipPath>

              <clipPath id="cdp-clip-big">
                <rect
                  x={0}
                  width={420}
                  height={((470.5 - 70.5) * p2) / 100}
                  y={470.5 - ((470.5 - 70.5) * p2) / 100}
                  fill="green"
                />
              </clipPath>
            </defs>

            <image width={420} height={401} y={70} xlinkHref={progressBg} />

            <g>
              <path
                clipPath="url(#cdp-clip-small)"
                opacity="0.7"
                d="M0 263.5V437L10 438.5H20L35 440.5L45.5 440V424H113V218L34.5 189L20 219V246L10 243L0 263.5Z"
                fill="#971B1E"
              />

              <path
                clipPath="url(#cdp-clip-big)"
                opacity="0.7"
                d="M45.5 424V442L203.5 464.5L283 457.5L420 470.5V424H335V135L261 70.5L185 149.5V424H45.5Z"
                fill="#971B1E"
              />
            </g>
          </svg>
        </div>

        <div className="cd-progress__fg">
          <div className="cd-progress__circle">{p}%</div>
          <div className="cd-progress__circle">{p2}%</div>
        </div>
      </div>
    </div>
  )
}
