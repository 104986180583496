/**
 * @description The ContactsPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useServerSettings } from '@/hooks/useServerSettings'
import { ss } from '@/features/info/stringSource'
import { Contacts } from '@/features/info/components/Contacts'

export const ContactsPage: React.FC = function (props) {
  const { isLoading } = useServerSettings()

  // todo add background
  return (
    <PageLayout
      isLoading={isLoading}
      fullpage={true}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <Contacts />
    </PageLayout>
  )
}
