/**
 * @description The ConstructionItemPage component.
 */
import React, { useMemo } from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useParams } from 'react-router'
import { useConstructionItem } from '@/features/posts/hooks/useConstructionItem'
import { Gallery, GalleryPhoto } from '@/components/Gallery/Gallery'
import { useGoBack } from '@/hooks/useGoBack'

export const ConstructionItemPage: React.FC = function (props) {
  const params: { slug: string } = useParams()
  const itemQuery = useConstructionItem(params.slug)

  const photos: GalleryPhoto[] = useMemo(() => {
    if (!itemQuery.data) {
      return []
    }
    return itemQuery.data.gallery_photos.map((photo) => ({
      src: photo.photo,
      srcMobile: photo.mobile_photo || undefined,
      alt: photo.alt || undefined,
    }))
  }, [itemQuery.data])

  const goBack = useGoBack()

  return (
    <PageLayout
      isLoading={itemQuery.isLoading}
      isError={itemQuery.isError}
      footer={false}
      fullpage
    >
      <Gallery
        photos={photos}
        label={itemQuery.data?.title}
        closeButton
        onClose={() => goBack('/construction')}
        zOverAll={true}
      />
    </PageLayout>
  )
}
