import { api } from '@/services/api'
import { IFlat, FlatManager } from '@/features/flats/services/flatManager'
import { appConf } from '@/config'
import { Locale } from '@creatorum/react-ml/dist/Locale'

export function getFlatsData(locale: Locale | null) {
  return api.get<FlatManager>(
    '/flats/',
    {},
    {
      headers: {
        'Accept-Language': locale,
      },
      transformResponse: (raw) => {
        const data: any[] = [...JSON.parse(raw)]
        const flats: IFlat[] = data.map((l) => {
          const planCode = l.plan_code.toLowerCase().replace('а', 'a')
          return {
            id: l.id,
            number: l.number,
            planCode,
            isAvailable: !!l.is_for_sale,
            isHidePrice: !!l.is_price_unknown,
            price: l.price,
            priceString: l.price_display,
            floorNumber: l.floor.number,
            floorID: l.floor.id,
            urlPlanSVG: `${appConf.assetsURL}/flats/plans/png/${planCode}.png`,
            urlPlanPDF: `${appConf.assetsURL}/flats/plans/pdf/${planCode}.pdf`,
            urlTour: `${appConf.assetsURL}/panorams/${l.floor.number}/index.html`,
            area: l.area ? parseFloat(l.area) : 0,
            bedrooms: l.bedrooms,
            isLiving: l.purpose === 0,
            loggia: l.loggia,
            terrace: l.terrace,
          }
        })

        return new FlatManager(flats, (f) => f.isLiving)
      },
    },
    {
      i18n: true,
    },
  )
}
