import { useMemo } from 'react'
import { FlatManager } from '@/features/flats/services/flatManager'
import { floorPlansConf } from '@/features/flats/floor-plans/conf'

export function useFloorNav(currentFloor: number, flatsManager?: FlatManager) {
  // extract all floors numbers and sort them
  const floorsNumbers = useMemo(
    () =>
      Object.keys(floorPlansConf)
        .map((k) => parseInt(k, 10))
        .sort((a, b) => a - b),
    [],
  )

  // filter available floors only
  const availableFloorsNumbers = useMemo(() => {
    if (!flatsManager) return []
    return floorsNumbers
    return floorsNumbers.filter((n) => flatsManager?.isFloorAvailable(n))
  }, [floorsNumbers, flatsManager])

  // find current floor
  const current = useMemo(() => {
    if (availableFloorsNumbers.length === 0) return null
    if (availableFloorsNumbers.length === 1) return availableFloorsNumbers[0]
    if (availableFloorsNumbers.indexOf(currentFloor) > -1) {
      return currentFloor
    }
    return availableFloorsNumbers[
      Math.floor((availableFloorsNumbers.length - 1) / 2)
    ]
  }, [currentFloor, availableFloorsNumbers])

  // find prev
  const prev = useMemo(() => {
    if (current === null) return null
    const ci = availableFloorsNumbers.indexOf(current)
    let found = ci - 1
    if (found < 0) found = availableFloorsNumbers.length - 1
    return availableFloorsNumbers[found]
  }, [current, availableFloorsNumbers])

  // find next
  const next = useMemo(() => {
    if (current === null) return null
    const ci = availableFloorsNumbers.indexOf(current)
    let found = ci + 1
    if (found > availableFloorsNumbers.length - 1) found = 0
    return availableFloorsNumbers[found]
  }, [current, availableFloorsNumbers])

  return {
    floorsNumbers,
    availableFloorsNumbers,
    current,
    prev,
    next,
    isReallyHasNext: next !== null && current !== null ? next > current : false,
    isReallyHasPrev: prev !== null && current !== null ? prev < current : false,
  }
}
