/**
 * @description The Gallery component.
 */
import React, { ReactNode, useEffect, useState } from 'react'
import { makeMods } from '@creatorum/bem'
import { Navigation, Keyboard, Controller, Swiper as SwiperClass } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { useIsMobile } from '@/hooks/useIsMobile'
import './Gallery.scss'
import { ReactComponent as CloseIcon } from './i/close.svg'

export type GalleryPhoto = {
  alt?: string
  src: string
  srcMobile?: string
}

type Props = {
  photos: GalleryPhoto[]
  closeButton?: boolean
  onClose?: () => void
  layout?: 'simple' | 'labeled' | 'subgallery'
  label?: ReactNode
  onSwiper?: (swiper: SwiperClass) => void
  controlledSwiper?: SwiperClass | null
  zOverAll?: boolean
}

export const Gallery: React.FC<Props> = function (props) {
  const {
    photos,
    closeButton,
    onClose,
    label,
    layout = 'simple',
    onSwiper,
    controlledSwiper,
    zOverAll = false,
  } = props
  const isMobile = useIsMobile()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (closeButton && onClose) {
      const handler = (e: KeyboardEvent) => {
        if (e.key === 'Escape' && onClose) {
          onClose()
        }
      }
      document.addEventListener('keydown', handler)
      return () => {
        document.removeEventListener('keydown', handler)
      }
    }
  }, [closeButton, onClose])

  const [currentIndex, setCurrentIndex] = useState(0)
  const currentToShow = currentIndex + 1
  const currentValue =
    currentToShow < 10 ? `0${currentToShow}` : `${currentToShow}`

  return (
    <div
      className={makeMods('gallery', {
        [layout]: true,
        'z-over-all': zOverAll,
      })}
    >
      <div className="gallery__cont">
        <div className="gallery__slider">
          <Swiper
            slidesPerView={1}
            navigation
            keyboard
            speed={900}
            resistance={true}
            resistanceRatio={0}
            modules={[
              Navigation,
              Keyboard,
              ...(controlledSwiper ? [Controller] : []),
            ]}
            onSwiper={onSwiper}
            controller={
              controlledSwiper ? { control: controlledSwiper } : undefined
            }
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
          >
            {photos.map((photo, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={`${photo.src}-${i}`}>
                <div className="gallery__slide">
                  <img
                    className="gallery__img"
                    src={
                      isMobile && photo.srcMobile ? photo.srcMobile : photo.src
                    }
                    alt={photo.alt}
                  />
                  <div className="gallery__slide-over">
                    <i />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="gallery__footer">
          {label && <div className="gallery__label">{label}</div>}
          <div className="gallery__counter">
            <b>{currentValue}</b>/
            {photos.length < 10 ? `0${photos.length}` : photos.length}
          </div>
          <div className="gallery__label" />
        </div>
      </div>

      {closeButton && (
        <button
          className="gallery__close"
          type="button"
          onClick={onClose || console.log}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  )
}
