/**
 * @description The InfoPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { InfoContent } from '@/features/info/components/InfoContent/InfoContent'
import { ss } from '@/features/info/stringSource'
import { useTranslate } from '@/hooks/useTranslate'

export const InfoPage: React.FC = function (props) {
  const t = useTranslate()

  return (
    <PageLayout
      title={ss.info.info.title}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <InfoContent content={t(ss.info.info.content)} />
    </PageLayout>
  )
}
