/**
 * @description The HomeHotspot component.
 */
import React, { ReactElement, useMemo } from 'react'
import './HomeHotspot.scss'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { useIsMobile } from '@/hooks/useIsMobile'

type Props = {
  label: ReactElement
  sublabel?: ReactElement
  icon: ReactElement
  to: string
}

export const HomeHotspot: React.FC<Props> = function (props) {
  const { label, sublabel, icon, to } = props
  const isMobile = useIsMobile()
  const goTo = useGoToWithLocale()

  const rootProps = useMemo(() => {
    if (isMobile) {
      return {
        onTouchStart: () => goTo(to),
      }
    }
    return {
      onClick: () => goTo(to),
    }
  }, [goTo, isMobile, to])

  return (
    <button type="button" {...rootProps} className="home-hotspot">
      <span className="home-hotspot__cont">
        <span className="home-hotspot__icon">
          <span className="home-hotspot__icon-wrap">{icon}</span>
        </span>
        <span className="home-hotspot__text">
          <span className="home-hotspot__label">{label}</span>
          {sublabel && (
            <span className="home-hotspot__sublabel">{sublabel}</span>
          )}
        </span>
      </span>
    </button>
  )
}
