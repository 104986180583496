/**
 * @description The CharsPage component.
 */
import React from 'react'
import { useChars } from '@/features/info/hooks/useChars'
import { PageLayout } from '@/components/PageLayout'
import { CharsSlider } from '@/features/info/components/CharsSlider'

export const CharsPage: React.FC = function (props) {
  const chars = useChars()
  return (
    <PageLayout fullpage isLoading={chars.isLoading}>
      {chars.data && <CharsSlider chars={chars.data} />}
    </PageLayout>
  )
}
