/**
 * @description The NewsList component.
 */
import React, { useMemo, useState } from 'react'
import { NewsListItem } from '@/features/posts/api/NewsAPI'
import { NewsListItem as ListItem } from './NewsListItem'
import { Button } from '@/components/Button'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/posts/stringSource'
import { ReactComponent as LoadingIcon } from './i/loading.svg'
import './NewsList.scss'

type Props = {
  items: NewsListItem[]
}

export const NewsList: React.FC<Props> = function (props) {
  const [portion, setPortion] = useState(3)
  const { items } = props

  const itemsToShow = useMemo(() => items.slice(0, portion), [items, portion])

  return (
    <div className="news-list">
      <div className="news-list__cont">
        <div className="news-list__list">
          {itemsToShow.map((news) => (
            <ListItem key={news.id} {...news} />
          ))}
        </div>

        {portion < items.length && (
          <div className="news-list__footer">
            <Button
              as="button"
              onClick={() => setPortion(portion + 3)}
              icon={<LoadingIcon />}
            >
              <T p={ss.posts.news.loadMore} />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
