/**
 * @description The FlatPage component.
 */
import React from 'react'
import { useIsMobile } from '@/hooks/useIsMobile'
import { PageLayout } from '@/components/PageLayout'
import { useFlatsData } from '@/features/flats/hooks/useFlatsData'
import { FlatManager } from '@/features/flats/services/flatManager'
import { useWrongFlatRedirect } from '@/features/flats/hooks/useWrongFlatRedirect'
import { useFloorFlatParams } from '@/features/flats/hooks/useFloorFlatParams'
import { FlatView } from '@/features/flats/components/FlatView/FlatView'

export const FlatPage: React.FC = function (props) {
  const canRender = useWrongFlatRedirect()
  const isMobile = useIsMobile()
  const { isLoading, isError, data } = useFlatsData()
  const { floorNumber, flatID } = useFloorFlatParams()

  return (
    <PageLayout
      isLoading={!canRender || isLoading}
      isError={isError}
      fullpage
      footer={!isMobile}
      footerProps={{ variant: 'dark' }}
    >
      <FlatView
        floorNumber={floorNumber as number}
        flatManager={data as FlatManager}
        flatId={flatID as number}
      />
    </PageLayout>
  )
}
