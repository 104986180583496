/**
 * Feature config file
 */

/**
 * Define config here
 */
export const featureConf = {
  name: 'info',
}

export default featureConf
