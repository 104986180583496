/**
 * @description The DateView component.
 */
import React, { HTMLAttributes, useMemo } from 'react'
import format from 'date-fns/format'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { dateFNSLocale } from '@/dateFNS'

type Props = {
  date: string | Date
  format?: string
} & HTMLAttributes<HTMLSpanElement>

export const DateView: React.FC<Props> = function (props) {
  const { date, format: formatValue = 'dd.MM.yyyy', ...rest } = props
  const locale = useLocale() as keyof typeof dateFNSLocale

  const dateString = useMemo(() => {
    let d = date as Date
    if (typeof date === 'string') {
      d = new Date(date)
    }
    return format(d, formatValue, {
      locale: dateFNSLocale[locale],
    })
  }, [date, formatValue, locale])

  return <span {...rest}>{dateString}</span>
}
