import { useQuery } from 'react-query'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { getFlatsData } from '@/features/flats/api/FlatsAPI'
import { FlatManager } from '@/features/flats/services/flatManager'
import { AxiosError } from 'axios'

export function useFlatsData() {
  const locale = useLocale()
  return useQuery<FlatManager, AxiosError>(
    ['flats', locale],
    // eslint-disable-next-line no-return-await
    async () => {
      const res = await getFlatsData(locale)
      return res.data
    },
  )
}
