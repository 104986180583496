import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { useQuery } from 'react-query'
import { getConstructionItem } from '@/features/posts/api/ConstructionAPI'

export function useConstructionItem(slug: string | null) {
  const locale = useLocale()
  return useQuery(
    ['constructionItems', slug, locale],
    async () => {
      const res = await getConstructionItem(slug as string)
      return res.data
    },
    {
      enabled: slug !== null,
    },
  )
}
