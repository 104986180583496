import { useEffect, useRef, useState, RefObject, useCallback } from 'react'

export interface IUsePanoramaOptions {
  element: RefObject<HTMLElement>
  panoramaURL: string
  onLoaded?: (pano: any) => any
  addSkin?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultOnLoaded = () => {}

export function usePanorama(options: IUsePanoramaOptions) {
  const {
    element,
    panoramaURL,
    onLoaded = defaultOnLoaded,
    addSkin = true,
  } = options
  const [loaded, setLoaded] = useState(false)
  const panoRef = useRef<any | null>(null)

  useEffect(() => {
    const mountState = {
      isMounted: true,
    }
    // eslint-disable-next-line new-cap
    const pano = new (window as any).pano2vrPlayer(
      element.current?.getAttribute('id'),
    )
    panoRef.current = pano
    if (addSkin) {
      // eslint-disable-next-line new-cap,no-new
      new (window as any).pano2vrSkin(pano)
    }
    pano.readConfigUrlAsync(panoramaURL, () => {
      if (mountState.isMounted) {
        onLoaded(pano)
        setLoaded(true)
      }
    })

    return () => {
      mountState.isMounted = false
    }
  }, [addSkin, element, onLoaded, panoramaURL])

  const goToScene = useCallback((scene: string) => {
    if (panoRef.current) {
      panoRef.current.openNext(`{${scene}}`, '')
    }
  }, [])

  return {
    pano: panoRef,
    loaded,
    goToScene,
  }
}
