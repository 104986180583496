export const GMAP_API_KEY = 'AIzaSyBJtcqm6f4xStMeS7S2ZMS59WPeSq0ip8c'
export const GMAP_STYLES = [
  {
    featureType: 'administrative',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'landscape',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#84afa3',
      },
      {
        lightness: 52,
      },
    ],
  },
  {
    stylers: [
      {
        saturation: -77,
      },
    ],
  },
  {
    featureType: 'road',
  },
]

export const CONTACTS_MAP_MARKER = {
  lat: 48.45795511061177,
  lng: 35.05987741670077,
}
