import { IStringSource, makeMap } from '@creatorum/react-ml/dist/StringSource'
import { featureConf } from './config'

const strings = {
  [featureConf.name]: {
    // WARNING! Do not change the scope name!
    documents: {
      title: 'Documents',
    },
    howToBuy: {
      title: 'How to buy',
    },
    news: {
      title: 'News',
      loadMore: 'Load more',
      readMore: 'Read',
    },
    newsItem: {
      back: 'back',
    },
    construction: {
      title: 'Construction dynamics',
    },
  },
}

export const stringSource: IStringSource & typeof strings = strings

export const stringMap: typeof strings = makeMap(strings) as typeof strings
export const ss = stringMap

export default strings
