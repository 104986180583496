import bg from './i/bg.jpg'

export type HouseConfFloor = {
  floor: number
  path: string
}

const floors: HouseConfFloor[] = [
  {
    floor: 24,
    path: 'M974 275V308L1144 213L1306 287.5V249L1193.5 192.5L1185 185L1153 203.5L1144 190.5L1074.5 231.5L1054 223L974 275Z',
  },
  {
    floor: 23,
    path: 'M1144 213L974 308V333.5L1144 241.5L1306 313V287.5L1144 213Z',
  },
  {
    floor: 22,
    path: 'M974 360.5V333.5L1144 241.5L1306 313V341.5L1144 275.5L974 360.5Z',
  },
  {
    floor: 21,
    path: 'M974 385.5V360.5L1144 275.5L1306 341.5V368L1216 332.5V324L1144 296.5L1091 322V331L974 385.5Z',
  },
  {
    floor: 20,
    path: 'M1091 331L974 385.5V412L1144 339L1206 361L1306 396.5V368L1216 332.5V324L1144 296.5L1091 322V331Z',
  },
  {
    floor: 19,
    path: 'M1144 339L974 412V439L1144 366L1306 423.5V396.5L1144 339Z',
  },
  {
    floor: 18,
    path: 'M1144 366L974 439V464L1144 397.5L1306 449V423.5L1206.5 388L1144 366Z',
  },
  {
    floor: 17,
    path: 'M1144 397.5L974 464V490L1144 430.5L1306 476.5V449L1144 397.5Z',
  },
  {
    floor: 16,
    path: 'M1144 430.5L974 490V516.5L1144 463.5L1306 504V476.5L1144 430.5Z',
  },
  {
    floor: 15,
    path: 'M1144 463.5L974 516.5V541L1144 495.5L1306 531V504L1144 463.5Z',
  },
  {
    floor: 14,
    path: 'M1144 495.5L974 541V568L1144 528.5L1306 559V531L1144 495.5Z',
  },
  {
    floor: 13,
    path: 'M1144 528.5L974 568V592.5L1144 560.5L1306 585.5V559L1144 528.5Z',
  },
  {
    floor: 12,
    path: 'M1144 560.5L974 592.5V619.5L1144 593.5L1306 613.5V585.5L1144 560.5Z',
  },
  {
    floor: 11,
    path: 'M1144 593.5L974 619.5V644L1144 624.5L1306 640V613.5L1144 593.5Z',
  },
  {
    floor: 10,
    path: 'M1144 624.5L974 644V669.5L1144 656.5L1306 667.5V640L1144 624.5Z',
  },
  {
    floor: 9,
    path: 'M1144 656.5L974 669.5V695.5L1144 689L1306 695V667.5L1144 656.5Z',
  },
  {
    floor: 8,
    path: 'M1144 689L974 695.5V722H1144H1306V695L1144 689Z',
  },
  {
    floor: 7,
    path: 'M1144 722H974V748.5L1144 754L1306 749.5V722H1144Z',
  },
  {
    floor: 6,
    path: 'M1144 754L974 748.5V774.5L1144 786L1306 777V749.5L1144 754Z',
  },
  {
    floor: 5,
    path: 'M1144 786L974 774.5V799.5L1144 818L1306 804V777L1144 786Z',
  },
  {
    floor: 4,
    path: 'M1144 818L974 799.5V826L1144 851L1306 831.5V804L1144 818Z',
  },
  {
    floor: 3,
    path: 'M1144 851L974 826V851L1144 881.5L1306 856.5V831.5L1144 851Z',
  },
  {
    floor: 2,
    path: 'M1306 856.5L1144 881.5L974 851V865.5L1144.5 896L1306 869.5V856.5Z',
  },
  {
    floor: 1,
    path: 'M1031 989L666 857.5V881L1030 1036L1187.5 994.5L1306 1029.5L1379 1002L1380.5 982.5L1340.5 972.5L1306 982.5L1187.5 952.5L1031 989Z',
  },
]

export const houseConf = {
  svgWidth: 1920,
  svgHeight: 1080,
  bgWidth: 1920,
  bgHeight: 1080,
  bgX: 0,
  bgY: 0,
  bg,
  floors,
}
