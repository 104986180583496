import { featureConf } from '@/features/flats/config'
import {
  StateExtensionBuilder,
  ExtAction as StateExtAction,
} from '@creatorum/react-duck/dist/StateExtension/StateExtension'
import { SimpleDuck } from '@creatorum/react-duck/dist/core/duckStack'
import { StoreState, Reducer } from '@creatorum/react-duck/dist/core/reduxStack'
import { FlatTableSortColumn } from '@/features/flats/services/flatManager'

/** namespace */
export const NAMESPACE = `${featureConf.name}/search`

/** StateExtension */
const stateExt = new StateExtensionBuilder(NAMESPACE).make()

/** initialState */
const initialState = {
  bedrooms: 0,
  floorFrom: 0,
  floorTo: 0,
  areaFrom: 0,
  areaTo: 0,
  orderBy: 'floor',
  orderDirection: -1,
  isMobileOpenedList: false,
  isMobileCardView: true,
  isSetDefaults: false,
}
type InitialState = typeof initialState & {
  orderBy: FlatTableSortColumn
}

/** action creators */
export const { set } = stateExt.actionCreators
export const setBedrooms = (bedrooms: number) =>
  set({
    bedrooms,
  })
export const setFloor = (from: number, to: number) =>
  set({
    floorFrom: from,
    floorTo: to,
  })
export const setArea = (from: number, to: number) =>
  set({
    areaFrom: from,
    areaTo: to,
  })
export const setOrder = (
  by: FlatTableSortColumn = 'floor',
  orderDirection: 1 | -1 = -1,
) =>
  set({
    orderBy: by,
    orderDirection,
  })
export const setIsMobileOpenedList = (is: boolean) =>
  set({
    isMobileOpenedList: is,
  })
export const setIsMobileCardView = (is: boolean) =>
  set({
    isMobileCardView: is,
  })
export const setIsSetDefaults = (is: boolean) =>
  set({
    isSetDefaults: is,
  })

/** reducer */
const reducer: Reducer<InitialState, StateExtAction> = (
  state = initialState as InitialState,
  action,
) => stateExt.reducer(state, action) as InitialState

/** selectors */
export const { selectField } = stateExt.selectors
export const selectAll = (s: StoreState) => s[NAMESPACE] as InitialState

/** connectable interface */
export const connectable: SimpleDuck = {
  namespace: NAMESPACE,
  reducer,
  sagas: [],
}
