/**
 * @description The Footer component.
 */
import React, { useMemo } from 'react'
import { useServerSettings } from '@/hooks/useServerSettings'
import { Mods } from '@/types'
import { makeMods, mergeMods } from '@creatorum/bem'
import { ReactComponent as FBIcon } from '@/images/social-fb.svg'
import { ReactComponent as InstaIcon } from '@/images/social-insta.svg'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/stringSources'
import './Footer.scss'

export type Props = {
  mods?: Mods
  variant?: 'light' | 'dark'
}

export const Footer: React.FC<Props> = function (props) {
  const { mods, variant = 'dark' } = props
  const { facebook, instagram } = useServerSettings()

  const modsToUse = useMemo(
    () =>
      mergeMods(mods || [], {
        [variant]: true,
      }),
    [mods, variant],
  )

  return (
    <footer className={makeMods('footer', modsToUse)}>
      <div className="footer__cont">
        <div className="footer__social">
          <a
            href={facebook}
            target="_blank"
            rel="noreferrer"
            className="footer__social-el"
          >
            <FBIcon />
          </a>
          <a
            href={instagram}
            target="_blank"
            rel="noreferrer"
            className="footer__social-el"
          >
            <InstaIcon />
          </a>
        </div>

        <div className="footer__copy">
          <T
            p={ss.shared.footer.copyrights}
            params={{ year: new Date().getFullYear() }}
          />
        </div>
      </div>
    </footer>
  )
}
