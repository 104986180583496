/**
 * @description The GoogleMap component.
 */
import React, { useMemo, useState, Fragment, useEffect } from 'react'
import { GoogleMap as GM, LoadScript, Marker } from '@react-google-maps/api'
import { GMAP_API_KEY, GMAP_STYLES } from '@/googleMapConfig'
import { GoogleMapMarker } from './GoogleMapMarker'
import './GoogleMap.scss'
import { GoogleMapMarker as TMarker } from '@/types'
import mmIcon from './i/mainMarkerIcon.svg'
import { useRem } from '@/hooks/useRem'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'

type Props = {
  center: {
    lat: number
    lng: number
  }
  zoom: number
  options?: {
    [p: string]: any
  }
  markers?: TMarker[]
}

export const GoogleMap: React.FC<Props> = function (props) {
  const { center, zoom, options = {}, markers = [] } = props
  const locale = useLocale()
  const [loaded, setLoaded] = useState(false)

  const gmapProps = useMemo(
    () => ({
      center,
      zoom,
      options: {
        styles: GMAP_STYLES as google.maps.MapTypeStyle[],
        disableDefaultUI: true,
        center,
        ...options,
      },
      onLoad: (gmapInstance: google.maps.Map) => {
        setTimeout(() => {
          gmapInstance.setCenter(center)
        }, 1000)
      },
    }),
    [center, options, zoom],
  )

  return (
    <div className="google-map">
      <div className="google-map__cont">
        <LoadScript
          language={locale as string}
          googleMapsApiKey={GMAP_API_KEY}
          onLoad={() => setLoaded(true)}
        >
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <GM mapContainerClassName="google-map__renderer" {...gmapProps}>
            {loaded && (
              <>
                {markers.map((el) => (
                  <GoogleMapMarker
                    key={el.id}
                    label={el.label}
                    lat={el.point.lat}
                    lng={el.point.lng}
                    isMain={el.type === 'main'}
                    icon={el.icon}
                    iconSize={el.iconSize}
                  />
                ))}
              </>
            )}
          </GM>
        </LoadScript>
      </div>
    </div>
  )
}
