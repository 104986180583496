export function calcLinearDeps(
  aMin: number,
  aMax: number,
  a: number,
  bMin: number,
  bMax: number,
) {
  /* eslint-disable no-nested-ternary */
  const bNext = ((a - aMin) / (aMax - aMin)) * (bMax - bMin) + bMin
  if (bMax > bMin) {
    return bNext < bMin ? bMin : bNext > bMax ? bMax : bNext
  }
  return bNext < bMax ? bMax : bNext > bMin ? bMin : bNext
}
