/**
 * @description The CharsSlider component.
 */
import React, { useState, useCallback, useRef, useEffect } from 'react'
import { CharsItem } from '@/features/info/api/CharsAPI'
import { useIsMobile } from '@/hooks/useIsMobile'
import './CharsSlider.scss'
import gsap from 'gsap'
import { useRem } from '@/hooks/useRem'
import { ReactComponent as PrevIcon } from './i/arr-prev.svg'
import { ReactComponent as NextIcon } from './i/arr-next.svg'

type Props = {
  chars: CharsItem[]
}

export const CharsSlider: React.FC<Props> = function (props) {
  const { chars } = props
  const [slide, setSlide] = useState(0)
  const dir = useRef(0)
  const isMobile = useIsMobile()
  const { rem } = useRem()
  const animationPlayingRef = useRef(false)
  const slidesRef = useRef<HTMLDivElement[]>([])

  const handlePrev = useCallback(() => {
    if (animationPlayingRef.current) return
    let next = slide - 1
    if (next < 0) next = chars.length - 1
    dir.current = -1
    setSlide(next)
  }, [slide, setSlide, chars])

  const handleNext = useCallback(() => {
    if (animationPlayingRef.current) return
    let next = slide + 1
    if (next >= chars.length) next = 0
    dir.current = 1
    setSlide(next)
  }, [slide, setSlide, chars])

  useEffect(() => {
    const rootStyle = {
      show: {
        top: 0,
        left: '50%',
        x: '-50%',
        y: 0,
        width: '100%',
        height: window.innerHeight,
        borderRadius: 0,
        boxShadow: 'none',
      },
      willShow: {
        left: '50%',
        top: '100%',
        x: '-50%',
        ...(isMobile
          ? {
              y: `${rem(-60)}px`,
              width: `${rem(342)}px`,
              height: `${rem(60)}px`,
              borderRadius: `${rem(12)}px ${rem(12)}px 0 0`,
              boxShadow: `0px ${rem(-10)}px ${rem(80)}px rgba(0, 15, 34, 0.3)`,
            }
          : {
              y: `${rem(-240)}px`,
              width: `${rem(1186)}px`,
              height: `${rem((1186 / 16) * 9)}px`,
              borderRadius: `${rem(32)}px ${rem(32)}px 0 0`,
              boxShadow: `0px ${rem(-10)}px ${rem(80)}px rgba(0, 15, 34, 0.3)`,
            }),
      },
      preWillShow: {
        left: '50%',
        top: '100%',
        x: '-50%',
        y: 0,
        ...(isMobile
          ? {
              width: `${rem(342 / 10)}px`,
              height: `${rem(60 / 10)}px`,
              borderRadius: `${rem(12)}px ${rem(12)}px 0 0`,
            }
          : {
              width: `${rem(1186 / 10)}px`,
              height: `${rem(((1186 / 16) * 9) / 10)}px`,
              borderRadius: `${rem(32)}px ${rem(32)}px 0 0`,
            }),
      },
    }

    const textStyle = {
      show: {
        opacity: 1,
        scale: 1,
      },
      willShow: {
        opacity: 0,
        scale: 1,
      },
    }
    const textSelector = '.chars-slider-slide__fg'

    const bgOverflowStyle = {
      show: {
        opacity: 1,
      },
      willShow: {
        opacity: 0,
      },
    }
    const bgOverflowSelector = '.chars-slider-slide__bg-over'

    // don't run for the first render
    if (dir.current === 0) {
      gsap.set(
        slidesRef.current.filter(
          (el, index) => index !== slide && index !== slide + 1,
        ),
        {
          zIndex: 1,
        },
      )

      gsap.set(slidesRef.current[slide], {
        ...rootStyle.show,
        zIndex: 4,
      })

      gsap.set(slidesRef.current[slide].querySelector(textSelector), {
        ...textStyle.show,
      })

      gsap.set(slidesRef.current[slide].querySelector(bgOverflowSelector), {
        ...bgOverflowStyle.show,
      })

      gsap.set(slidesRef.current[slide + 1], {
        ...rootStyle.willShow,
        zIndex: 5,
      })

      return
    }

    // mark animation as running now
    animationPlayingRef.current = true

    // init timeline
    const tl = gsap.timeline({
      onComplete: () => {
        animationPlayingRef.current = false
      },
    })
    tl.pause()

    // get current, pren, next slides
    const currentIndex = slide
    const currentSlide = slidesRef.current[currentIndex]
    const prevIndex = slide - 1 >= 0 ? slide - 1 : chars.length - 1
    const nextIndex = slide + 1 >= chars.length ? 0 : slide + 1
    const nextNextIndex = nextIndex + 1 >= chars.length ? 0 : nextIndex + 1
    const prevSlide = slidesRef.current[prevIndex]
    const nextSlide = slidesRef.current[nextIndex]
    const nextNextSlide = slidesRef.current[nextNextIndex]

    if (dir.current > 0) {
      // run forth

      tl.add(
        [
          gsap.set(
            slidesRef.current.filter(
              (el, index) => index !== currentIndex && index !== nextIndex,
            ),
            {
              zIndex: 1,
            },
          ),
          gsap.set(prevSlide, {
            zIndex: 4,
          }),
          gsap.set(currentSlide, {
            zIndex: 5,
          }),
          gsap.set(nextSlide, {
            zIndex: 6,
          }),
        ],
        0,
      )

      // show next slide
      tl.add(
        gsap.fromTo(
          currentSlide,
          {
            ...rootStyle.willShow,
          },
          {
            ...rootStyle.show,
            duration: 1.2,
            ease: 'easeInOut',
          },
        ),
        0,
      )
      tl.add(
        gsap.fromTo(
          currentSlide.querySelector(textSelector),
          textStyle.willShow,
          {
            ...textStyle.show,
            duration: 0.8,
            delay: 0.4,
          },
        ),
        0,
      )
      tl.add(
        gsap.fromTo(
          currentSlide.querySelector(bgOverflowSelector),
          bgOverflowStyle.willShow,
          {
            ...bgOverflowStyle.show,
            duration: 0.6,
          },
        ),
        '0',
      )

      // prepare next next slide
      tl.add(
        gsap.fromTo(
          nextSlide,
          {
            ...rootStyle.preWillShow,
          },
          {
            ...rootStyle.willShow,
            duration: 0.8,
            ease: 'easeInOut',
          },
        ),
        '-=0.8',
      )
      tl.add(
        gsap.set(nextSlide.querySelector(textSelector), textStyle.willShow),
        '-=0.8',
      )
      tl.add(
        gsap.set(
          nextSlide.querySelector(bgOverflowSelector),
          bgOverflowStyle.willShow,
        ),
        '-=0.8',
      )

      // hide current slide
    } else {
      // run back

      tl.add(
        [
          gsap.set(
            slidesRef.current.filter(
              (el, index) => index !== currentIndex && index !== nextIndex,
            ),
            {
              zIndex: 1,
            },
          ),
          gsap.set(currentSlide, {
            zIndex: 4,
          }),
          gsap.set(nextSlide, {
            zIndex: 5,
          }),
          gsap.set(nextNextSlide, {
            zIndex: 6,
          }),
        ],
        0,
      )

      // show current slide
      tl.add(
        gsap.set(currentSlide, {
          ...rootStyle.show,
        }),
      )
      tl.add(
        gsap.set(currentSlide.querySelector(textSelector), {
          ...textStyle.show,
        }),
      )
      tl.add(
        gsap.set(currentSlide.querySelector(bgOverflowSelector), {
          ...bgOverflowStyle.show,
        }),
      )

      // prepare next next slide
      tl.add(
        gsap.fromTo(
          nextNextSlide,
          {
            ...rootStyle.willShow,
          },
          {
            ...rootStyle.preWillShow,
            duration: 0.8,
            ease: 'easeInOut',
          },
        ),
        '0',
      )
      tl.add(
        gsap.set(nextNextSlide.querySelector(textSelector), {
          ...textStyle.willShow,
        }),
      )
      tl.add(
        gsap.set(nextNextSlide.querySelector(bgOverflowSelector), {
          ...bgOverflowStyle.willShow,
        }),
      )

      // hide current slide
      tl.add(
        gsap.fromTo(
          nextSlide,
          {
            ...rootStyle.show,
          },
          {
            ...rootStyle.willShow,
            duration: 1.2,
            ease: 'easeInOut',
          },
        ),
        0,
      )
      tl.add(
        gsap.fromTo(
          nextSlide.querySelector(textSelector),
          {
            ...textStyle.show,
          },
          {
            ...textStyle.willShow,
          },
        ),
        0,
      )
      tl.add(
        gsap.fromTo(
          nextSlide.querySelector(bgOverflowSelector),
          {
            ...bgOverflowStyle.show,
          },
          {
            ...bgOverflowStyle.willShow,
            duration: 0.5,
            delay: 0.7,
          },
        ),
        0,
      )
    }

    tl.play()

    // eslint-disable-next-line consistent-return
    return () => {
      tl.pause()
    }
  }, [slide, chars, rem, isMobile])

  // reset slides' refs on each render
  slidesRef.current = []

  /* eslint-disable react/no-danger */
  return (
    <div className="chars-slider">
      <div className="chars-slider__cont">
        {chars.map((s, i) => (
          <div
            ref={(el) => {
              if (el) slidesRef.current.push(el)
            }}
            key={s.id}
            className="chars-slider-slide"
          >
            <div className="chars-slider-slide__cont">
              <div className="chars-slider-slide__bg">
                <img src={isMobile ? s.photoMobile : s.photo} alt={s.title} />
                <div className="chars-slider-slide__bg-over" />
              </div>
              <div className="chars-slider-slide__fg">
                <h2 className="chars-slider-slide__title">{s.title}</h2>
                <div
                  className="chars-slider-slide__content"
                  dangerouslySetInnerHTML={{ __html: s.content }}
                />
              </div>
            </div>
          </div>
        ))}

        <div className="chars-slider__counter">
          <b>{slide + 1}</b>
          <span> / {chars.length}</span>
        </div>

        <div className="chars-slider__controls">
          <button
            className="chars-slider__control chars-slider__control--prev"
            type="button"
            onClick={handlePrev}
          >
            <PrevIcon />
          </button>
          <button
            className="chars-slider__control chars-slider__control--next"
            type="button"
            onClick={handleNext}
          >
            <NextIcon />
          </button>
        </div>
      </div>
    </div>
  )
}
