import { RouterType } from '@creatorum/react-router-helper/dist/Conf'

const isProd = process.env.NODE_ENV === 'production'

const conf = {
  name: 'project',
  isProduction: isProd,
  ml: {
    enabled: true,
    locales: ['en', 'ru', 'uk'],
    defaultLocale: 'uk',
    detector: {
      routerLangParam: 'locale',
    },
  },
  router: {
    routerType: RouterType.Hash,
    breadcrumbsSize: 1,
    useTrailingSlash: true,
  },
  api: {
    siteURL: isProd ? '' : '//beta-marshall.daytona.com.ua',
    baseURL: isProd ? '/api/' : '//beta-marshall.daytona.com.ua/api/',
  },
  publicURL: isProd ? '/public/static/app' : '',
  responsive: {
    mobile: 768,
  },
  assetsURL: '//3d.daytona.com.ua/marshall/assets',
  scenesURL: '//3d.daytona.com.ua/marshall/scenes',
  panoramsURL: '//3d.daytona.com.ua/marshall/panorams',
  windowURL: '//3d.daytona.com.ua/marshall-2',
}

export const appConf: typeof conf & { [k: string]: any } = conf
