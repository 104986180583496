import {
  makeRoutesRenderConf,
  RenderMap,
} from '@creatorum/react-router-helper/dist/route'
import { keys, routes } from './routes'
import { HomePage } from '@/features/info/pages/HomePage'
import { CharsPage } from '@/features/info/pages/CharsPage'
import { TeamPage } from '@/features/info/pages/TeamPage'
import { ContactsPage } from '@/features/info/pages/ContactsPage'
import { PlacementPage } from '@/features/info/pages/PlacementPage'
import { WindowPage } from '@/features/info/pages/WindowPage'
import { TourPage } from '@/features/info/pages/TourPage'
import { InfoPage } from '@/features/info/pages/InfoPage'

const renderMap: RenderMap = {
  [keys.home]: { contentComponent: HomePage },
  [keys.chars]: { contentComponent: CharsPage },
  [keys.team]: { contentComponent: TeamPage },
  [keys.contacts]: { contentComponent: ContactsPage },
  [keys.placement]: { contentComponent: PlacementPage },
  [keys.window]: { contentComponent: WindowPage },
  [keys.tour]: { contentComponent: TourPage },
  [keys.info]: { contentComponent: InfoPage },
}

export const routesRender = makeRoutesRenderConf(renderMap, routes)
