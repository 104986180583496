/**
 * @description The Apartments component.
 */
import React, {
  TouchEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import './Apartments.scss'
import { houseConf } from '@/features/flats/components/Apartments/houseConf'
import { Maybe } from '@/types'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { ApartmentsFloorPanel } from './ApartmentsFloorPanel'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/flats/stringSource'
import { FlatManager } from '@/features/flats/services/flatManager'
import { PromoPanel } from '@/features/flats/components/Apartments/PromoPanel'

type Props = {
  flatManager: FlatManager
}

export const Apartments: React.FC<Props> = function (props) {
  const { flatManager } = props

  const isMobile = useIsMobile()
  const goTo = useGoToWithLocale()

  const svgRef = useRef<Maybe<SVGSVGElement>>(null)
  const [hoveredFloor, setHoveredFloor] = useState<Maybe<number>>(null)
  const [animationHover, setAnimationHover] = useState<Maybe<number>>(null)
  const [selectionBCR, setSelectionBCR] = useState<Maybe<DOMRect>>(null)

  useEffect(() => {
    if (svgRef.current) {
      const selection = svgRef.current.querySelector(
        `[data-floor-selection="${hoveredFloor}"]`,
      )
      if (selection) {
        setSelectionBCR(selection.getBoundingClientRect())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGoToFloor = useCallback(
    (floorNumber: number) => {
      goTo(`/apartments/floor-${floorNumber}`)
    },
    [goTo],
  )

  const makePropsForSelection = (floorNumber: number) => ({
    onMouseEnter: (e: any) => {
      setHoveredFloor(floorNumber)
    },
    onClick: () => {
      if (isMobile) {
        if (hoveredFloor === floorNumber) {
          handleGoToFloor(floorNumber)
        }
      } else {
        handleGoToFloor(floorNumber)
      }
    },
  })

  useEffect(() => {
    const node = svgRef.current?.querySelector(
      `[data-floor-selection="${hoveredFloor}"]`,
    )
    if (node && hoveredFloor) {
      setSelectionBCR((node as SVGPathElement).getBoundingClientRect())
    }
  }, [hoveredFloor])

  const handleTouchMove = useCallback(
    (e: Event) => {
      if (!isMobile) {
        return
      }

      e.preventDefault()
      const touch = (e as unknown as TouchEvent<SVGSVGElement>)
        .changedTouches[0]
      if (touch) {
        const el = document.elementFromPoint(touch.clientX, touch.clientY)
        if (el === null) {
          return
        }
        const key = el.getAttribute('data-floor-selection')
        if (key !== null) {
          setHoveredFloor(parseInt(key, 10))
          setSelectionBCR(el.getBoundingClientRect())
        }
      }
    },
    [isMobile],
  )

  useEffect(() => {
    let current: Maybe<number> = null
    let t: any = 0
    const handler = () => {
      const conf =
        houseConf.floors[
          current === null
            ? Math.floor(houseConf.floors.length) - 1
            : houseConf.floors.findIndex((f) => f.floor === current) - 1
        ]
      if (conf) {
        current = conf.floor
        setAnimationHover(conf.floor)
      } else {
        clearInterval(t)
        setAnimationHover(null)
        setHoveredFloor(
          houseConf.floors[Math.floor(houseConf.floors.length / 2)].floor,
        )
      }
    }
    t = setInterval(handler, 100)
    return () => {
      clearInterval(t)
    }
  }, [setHoveredFloor])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (svgRef.current) {
      const node = svgRef.current
      node.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      })
      return () => {
        node.removeEventListener('touchmove', handleTouchMove)
      }
    }
  }, [handleTouchMove])

  const floorFlats = useMemo(
    () =>
      hoveredFloor !== null ? flatManager.getFloorFlats(hoveredFloor) : [],
    [flatManager, hoveredFloor],
  )
  const availableFloorFlats = useMemo(
    () => floorFlats.filter((f) => f.isAvailable),
    [floorFlats],
  )
  const isFlats = useMemo(
    () => floorFlats.every((f) => f.isLiving),
    [floorFlats],
  )

  return (
    <div className="apartments">
      <div className="apartments__cont">
        <div className="apartments__main">
          <svg
            ref={svgRef}
            className="apartments__svg"
            width={houseConf.svgWidth}
            height={houseConf.svgHeight}
            viewBox={`0 0 ${houseConf.svgWidth} ${houseConf.svgHeight}`}
            preserveAspectRatio="xMidYMid slice"
          >
            <image
              xlinkHref={houseConf.bg}
              x={houseConf.bgX}
              y={houseConf.bgY}
              width={houseConf.bgWidth}
              height={houseConf.bgHeight}
            />
            <g>
              {houseConf.floors.map((floorConf) => (
                <g key={floorConf.floor} className="apartments__selection">
                  <path
                    data-floor-selection={floorConf.floor}
                    opacity={
                      // eslint-disable-next-line no-nested-ternary
                      hoveredFloor === floorConf.floor
                        ? 0.6
                        : animationHover !== null &&
                          animationHover >= floorConf.floor
                        ? 0.3
                        : 0
                    }
                    fill="#A03033"
                    d={floorConf.path}
                    {...makePropsForSelection(floorConf.floor)}
                  />
                </g>
              ))}
            </g>
          </svg>
        </div>
        <div className="apartments__text">
          <T p={ss.flats.apartments.text} />
        </div>
        {hoveredFloor && (
          <ApartmentsFloorPanel
            floorNumber={hoveredFloor}
            targetBCR={selectionBCR}
            flats={availableFloorFlats.length}
            isFlat={isFlats}
          />
        )}
      </div>

      <PromoPanel />
    </div>
  )
}
