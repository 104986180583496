/**
 * @description The FloorNav component.
 */
import React, { ReactNode } from 'react'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/flats/stringSource'
import { ReactComponent as NextIcon } from './i/next.svg'
import { ReactComponent as PrevIcon } from './i/prev.svg'
import './FloorNav.scss'

type Props = {
  onPrev: () => void
  onNext: () => void
  current: ReactNode
}

export const FloorNav: React.FC<Props> = function (props) {
  const { onPrev, onNext, current } = props

  return (
    <div className="floor-nav">
      <div className="floor-nav__cont">
        <div className="floor-nav__current">
          <div className="floor-nav__current-v">{current}</div>
          <div className="floor-nav__current-l">
            <T p={ss.flats.floor.nav.label} />
          </div>
        </div>
        <div className="floor-nav__buttons">
          <button
            type="button"
            className="floor-nav__button floor-nav__button--next"
            onClick={onNext}
          >
            <NextIcon />
          </button>

          <button
            type="button"
            className="floor-nav__button floor-nav__button--prev"
            onClick={onPrev}
          >
            <PrevIcon />
          </button>
        </div>
      </div>
    </div>
  )
}
