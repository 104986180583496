import { IStringSource, makeMap } from '@creatorum/react-ml/dist/StringSource'
import { stringSource as info } from '@/features/info/stringSource'
import { stringSource as posts } from '@/features/posts/stringSource'
import { stringSource as flats } from '@/features/flats/stringSource'

const strings = {
  shared: {
    // WARNING! Do not change the scope name!
    ok: 'Ok',
    cancel: 'Cancel',
    close: 'Close',
    open: 'Open',
    back: 'Back',
    error: {
      default: 'Something went wrong. Please, try again later.',
    },
    header: {
      chooseAnApartments: 'Choose an apartments',
      searchAnApartments: 'Search by parameters',
    },
    footer: {
      copyrights: 'Marshall ©, {{ year }} г. Все права защищены.',
    },
    form: {
      input: {
        name: 'Имя',
        phone: 'Телефон',
        message: 'Сообщение',
      },
      submit: 'Отправить',
      submitting: 'Отправка...',
      validation: {
        required: 'Это поле обязательно',
      },
      result: {
        success: {
          title: 'Спасибо!',
          description:
            'Сообщение отправлено. Наш менеджер свяжется с Вами в ближайшее время',
        },
        error: 'Что-то пошло не так. Попробуйте еще раз.',
      },
    },
    menu: {
      about: {
        main: 'О нас',
        sub: {
          location: 'Локация',
          chars: 'Характеристики',
          team: 'Участники проекта',
        },
      },
      apartments: {
        main: 'Выбор квартиры',
        sub: {
          visual: 'Визуальный выбор',
          search: 'Подбор по параметрам',
        },
      },
      media: {
        main: 'Фото, видео',
        sub: {
          tour: 'Виртуальный тур',
          pano: 'Аэропанорама',
          gallery: 'Галерея',
        },
      },
      construction: 'Динамика строительства',
      howToBuy: 'Как купить',
      docs: 'Документы',
      info: 'Важная информация',
      news: 'Новости',
      contact: 'Контакты',
    },
    p404: {
      title: '404',
      subtitle: 'К сожалению эта страница недоступна!',
      text: 'Ссылка, по которой вы переходили, возможно повреждена или удалена.',
      button: 'Перейти на главную',
    },
  },
  ...info,
  ...posts,
  ...flats,
}

export const stringSource: IStringSource & typeof strings = strings

export const stringMap: typeof strings = makeMap(strings) as typeof strings
export const ss = stringMap

export default strings
