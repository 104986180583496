/**
 * @description The GalleryPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useGallery } from '@/features/posts/hooks/useGallery'
import { GalleryAlbums } from '@/features/posts/components/GalleryAlbums/GalleryAlbums'
import { GalleryAlbum } from '@/features/posts/api/GalleryAPI'

export const GalleryPage: React.FC = function (props) {
  const itemsQuery = useGallery()

  return (
    <PageLayout
      isLoading={itemsQuery.isLoading}
      isError={itemsQuery.isError}
      footer={false}
      fullpage
    >
      <GalleryAlbums albums={itemsQuery.data as GalleryAlbum[]} />
    </PageLayout>
  )
}
