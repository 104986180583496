import { useLocation } from 'react-router'
import { useMemo } from 'react'
import { rrh } from '@/services/rrh'

export function useCurrentPage() {
  const location = useLocation()
  return useMemo(() => {
    const { routeID, matches } = rrh.getCurrentState(location.pathname)
    return {
      page: rrh.routes.find((r) => r.id === routeID),
      matches,
    }
  }, [location.pathname])
}
