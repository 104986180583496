import { calcLinearDeps } from '@/utils/calcLinearDeps'

export type Position = {
  x: number
  y: number
}

export type Sensitivity = {
  top: number
  left: number
  right: number
  bottom: number
}

export type Size = {
  width: number
  height: number
}

export function calcMouseShiftFromCenter(
  position: Position,
  size: Size,
  sensitivity: Sensitivity,
  percentScale = 100,
) {
  const // input
    w = size.width

  const h = size.height
  const percentMiddle = percentScale / 2
  const leftSensitivity = sensitivity.left
  const rightSensitivity = sensitivity.right
  const topSensitivity = sensitivity.top
  const bottomSensitivity = sensitivity.bottom

  // recognition
  const x = (position.x / w) * percentScale
  const y = (position.y / h) * percentScale
  const isTop = y <= percentMiddle
  const isLeft = x <= percentMiddle
  const dx = isLeft ? percentMiddle - x : x - percentMiddle
  const dy = isTop ? percentMiddle - y : y - percentMiddle

  // calculation
  const shiftX = calcLinearDeps(
    0,
    percentMiddle,
    dx,
    0,
    isLeft ? leftSensitivity : rightSensitivity,
  )
  const shiftY = calcLinearDeps(
    0,
    percentMiddle,
    dy,
    0,
    isTop ? topSensitivity : bottomSensitivity,
  )
  const shiftXSigned = shiftX * (isLeft ? -1 : 1)
  const shiftYSigned = shiftY * (isTop ? -1 : 1)

  return {
    hor: shiftXSigned,
    vert: shiftYSigned,
  }
}
