/**
 * @description The TeamPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useTeam } from '@/features/info/hooks/useTeam'
import { ss } from '@/features/info/stringSource'
import { TeamList } from '@/features/info/components/TeamList'

export const TeamPage: React.FC = function (props) {
  const team = useTeam()

  // todo add background
  return (
    <PageLayout
      title={ss.info.team.title}
      isLoading={team.isLoading}
      isError={team.isError}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <TeamList teams={team.data || []} />
    </PageLayout>
  )
}
