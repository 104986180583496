import { api } from '@/services/api'
import { Locale } from '@creatorum/react-ml/dist/Locale'

export interface ISettings {
  phone: string
  phoneList: string[]
  instagram: string
  youtube: string
  facebook: string
  googleMap: string
  address: string
  salesOfficeAddress: string
  email: string
  buildProgress: number
  buildProgress2: number
  constructionFinishDate: string
  workingTime: string
}

export function get(locale: Locale) {
  return api.get<ISettings>(
    '/setting/',
    undefined,
    {
      headers: {
        'Accept-Language': locale,
      },
      transformResponse: (raw: any) => {
        const data = JSON.parse(raw)
        return {
          phone: data.phone,
          phoneList: data.phone_list,
          instagram: data.instagram,
          youtube: data.youtube,
          facebook: data.facebook,
          googleMap: data.google_map,
          address: data.address,
          salesOfficeAddress: data.sales_office_address,
          email: data.email,
          buildProgress: data.build_progress,
          buildProgress2: data.build_progress_2,
          constructionFinishDate: data.construction_finish_date,
          workingTime: data.working_time,
        }
      },
    },
    {
      i18n: true,
    },
  )
}
