import { useQuery } from 'react-query'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { getChars } from '@/features/info/api/CharsAPI'
import { useIsMobile } from '@/hooks/useIsMobile'

export function useChars() {
  const isMobile = useIsMobile()
  const locale = useLocale()
  return useQuery(['chars', locale], async () => {
    const res = await getChars(isMobile)
    return res.data
  })
}
