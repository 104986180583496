/**
 * @description The Compass component.
 */
import React, { SVGAttributes } from 'react'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { ReactComponent as IconRu } from './i/ru.svg'
import { ReactComponent as IconEn } from './i/en.svg'
import { ReactComponent as IconUk } from './i/uk.svg'

export const Compass: React.FC<SVGAttributes<SVGSVGElement>> = function (
  props,
) {
  const locale = useLocale()

  if (locale === 'ru') {
    return <IconRu {...props} />
  }

  if (locale === 'uk') {
    return <IconUk {...props} />
  }

  return <IconEn {...props} />
}
