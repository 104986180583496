import { Config } from '@creatorum/react-router-helper/dist/route'
import { featureConf } from './config'

const NAMESPACE = featureConf.name

export const keys = {
  apartments: `${NAMESPACE}/apartments`,
  floor: `${NAMESPACE}/floor`,
  flat: `${NAMESPACE}/flat`,
}

export const routes = [
  new Config({
    id: keys.apartments,
    path: '/apartments',
    mods: ['links-search'],
  }),
  new Config({
    id: keys.floor,
    path: '/apartments/floor-:floor',
    mods: ['links-search', 'header-dark'],
  }),
  new Config({
    id: keys.flat,
    path: '/apartments/floor-:floor/flat-:flat',
    mods: ['links-search', 'header-dark'],
  }),
]
