import { useQuery } from 'react-query'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { getTeamsList } from '@/features/info/api/TeamAPI'

export function useTeam() {
  const locale = useLocale()
  return useQuery(['teams', locale], async () => {
    const res = await getTeamsList()
    return res.data
  })
}
