/**
 * @description The DocumentsListEl component.
 */
import React from 'react'
import { DocumentsListItem } from '@/features/posts/api/DocumentsAPI'
import { ReactComponent as DownloadIcon } from './i/download.svg'
import format from 'date-fns/format'

type Props = {
  item: DocumentsListItem
}

export const DocumentsListEl: React.FC<Props> = function (props) {
  const { item } = props

  return (
    <div className="docs-el">
      <a
        href={item.file}
        rel="noreferrer"
        target="_blank"
        className="docs-el__cont"
      >
        <div className="docs-el__text">
          <h2 className="docs-el__title">{item.title}</h2>
          <div className="docs-el__date">
            {format(new Date(item.date), 'dd.MM.yyyy')}
          </div>
        </div>
        <div className="docs-el__media">
          <div className="docs-el__icon">
            <DownloadIcon />
          </div>
          <div className="docs-el__media-info">
            {item.fileExt}, {item.fileSize}
          </div>
        </div>
      </a>
    </div>
  )
}
