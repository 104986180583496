/**
 * @description The GalleryAlbums component.
 */
import React, { useMemo, useState } from 'react'
import { GalleryAlbum } from '@/features/posts/api/GalleryAPI'
import { Gallery, GalleryPhoto } from '@/components/Gallery/Gallery'
import { makeMods } from '@creatorum/bem'
import './GalleryAlbums.scss'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Controller, Keyboard, Navigation } from 'swiper'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useRem } from '@/hooks/useRem'

type Props = {
  albums: GalleryAlbum[]
}

type Album = {
  id: string
  name: string
  cover: string
  photos: GalleryPhoto[]
}

export const GalleryAlbums: React.FC<Props> = function (props) {
  const { albums: albumsRaw } = props
  const [current, setCurrent] = useState(0)
  const isMobile = useIsMobile()
  const { rem } = useRem()

  const albums: Album[] = useMemo(
    () =>
      albumsRaw.map((album) => ({
        id: album.id,
        name: album.title,
        cover: album.cover_photo,
        photos: album.photos.map((photo) => ({
          src: photo.photo,
          srcMobile: photo.mobile_photo,
          alt: photo.alt,
        })),
      })),
    [albumsRaw],
  )

  return (
    <div className="gallery-albums">
      <div className="gallery-albums__cont">
        <div className="gallery-albums__sliders">
          {albums.map((album, i) => (
            <div
              key={album.id}
              className={makeMods('gallery-albums__slider', {
                show: current === i,
              })}
            >
              <Gallery layout="subgallery" photos={album.photos} />
            </div>
          ))}
        </div>
        <div className="gallery-albums__nav">
          <div className="gallery-albums__nav-slider">
            <Swiper
              slidesPerView={2}
              navigation
              spaceBetween={rem(20)}
              speed={900}
              resistance={true}
              resistanceRatio={0}
              modules={[Navigation]}
              onSlideChange={(swiper) => setCurrent(swiper.activeIndex)}
            >
              {albums.map((album: Album, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <SwiperSlide key={`${album.cover}-${i}`}>
                  <div
                    onClick={() => setCurrent(i)}
                    className="gallery-albums__nav-slide"
                  >
                    <div className="gallery-albums__nav-img-wrap">
                      <img
                        className="gallery-albums__nav-img"
                        src={album.cover}
                        alt={album.name}
                      />
                    </div>
                    <div className="gallery-albums__nav-text">{album.name}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}
