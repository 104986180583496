/**
 * @description The Panorama component.
 */
import React, { useRef } from 'react'
import { usePanorama } from '@/hooks/usePanorama'
import { Maybe } from '@/types'
import './Panorama.scss'

type Props = {
  onLoaded: () => void
  url: string
  addSkin?: boolean
}

export const Panorama: React.FC<Props> = function (props) {
  const { onLoaded, url, addSkin = false } = props
  const rootRef = useRef<Maybe<HTMLDivElement>>(null)
  const id = useRef(`panorama-container-${new Date().getTime()}`)

  const panorama = usePanorama({
    element: rootRef,
    onLoaded,
    panoramaURL: url,
    addSkin,
  })

  return <div id={id.current} ref={rootRef} className="panorama" />
}
