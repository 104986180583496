/**
 * @description The Button component.
 */
import React, { ReactNode, createElement, useMemo } from 'react'
import './Button.scss'
import type { LinkProps } from 'react-router-dom'
import { ModsArray } from '@creatorum/bem/dist/modsFromArray'
import { ModsObject } from '@creatorum/bem/dist/modsFromObject'
import { Link } from '@creatorum/react-ml/dist/components/Link'
import { makeMods, mergeMods } from '@creatorum/bem'

export type Props = Partial<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> &
  Partial<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLLinkElement>,
      HTMLLinkElement
    >
  > &
  Partial<LinkProps> & {
    as?: 'button' | 'link' | 'routerLink'
    mods?: ModsArray | ModsObject
    icon?: ReactNode
    mobileIconOnly?: boolean
  }

export const Button: React.FC<Props> = function (props) {
  const {
    mods,
    as = 'button',
    icon,
    to = '/',
    children,
    className = '',
    mobileIconOnly,
    ...rest
  } = props

  const modsToUse = useMemo(
    () =>
      mergeMods(mods || [], {
        'icon': !!icon,
        'm-i-only': !!mobileIconOnly,
      }),
    [mods, icon, mobileIconOnly],
  )

  let element: string | typeof Link = 'button'
  if (as !== 'button') {
    element = as === 'link' ? 'a' : Link
  }
  const cnToUse = `${className} ${makeMods('button', modsToUse)}`.trim()

  return createElement(
    element,
    {
      ...rest,
      to: (as === 'button' || as === 'link' ? undefined : to) as string,
      className: cnToUse,
    },
    <span className="button__cont">
      <span className="button__content">{children}</span>
      {icon && <span className="button__icon">{icon}</span>}
    </span>,
  )
}
