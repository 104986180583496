/**
 * @description The PageLayout component.
 */
import React, { ReactNode, useEffect } from 'react'
import './PageLayout.scss'
import { Footer, Props as FooterProps } from '@/components/Footer'
import { T } from '@creatorum/react-ml/dist/components/T'
import { Loader } from './Loader'
import { emitLoadingDone } from '@/events/loading'

type Props = {
  isLoading?: boolean
  isLoadingBlocking?: boolean
  isError?: boolean
  footer?: boolean
  footerProps?: FooterProps
  bg?: ReactNode
  title?: string
  fullpage?: boolean
}

export const PageLayout: React.FC<Props> = function (props) {
  const {
    children,
    isLoading,
    isLoadingBlocking = true,
    isError,
    footer = false,
    footerProps = {},
    bg,
    title,
    fullpage = false,
  } = props

  useEffect(() => {
    if (!isLoading) emitLoadingDone()
  }, [isLoading])

  const renderContent = () =>
    isError ? (
      <div>Something went wrong</div>
    ) : (
      <>
        {fullpage ? (
          <div className="pl__fullpage">
            {children}
            {footer && (
              <div className="pl__footer pl__footer--fullpage">
                <Footer {...footerProps} />
              </div>
            )}
          </div>
        ) : (
          <div className="pl__content">
            <div className="pl__page">
              {title && (
                <h1 className="pl__page-title">
                  <T p={title} />
                </h1>
              )}
              <div className="pl__page-content">{children}</div>
            </div>
            {footer && (
              <div className="pl__footer">
                <Footer {...footerProps} />
              </div>
            )}
          </div>
        )}
      </>
    )

  return (
    <div className="pl">
      <div className="pl__cont">
        {bg && <div className="pl__bg">{bg}</div>}
        {isLoadingBlocking && <>{!isLoading && renderContent()}</>}
        {!isLoadingBlocking && renderContent()}
        <div className="pl__loader">
          <Loader show={!!isLoading} />
        </div>
      </div>
    </div>
  )
}
