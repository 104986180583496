/**
 * @description The HowToBuyOrder component.
 */
import React from 'react'
import { HowToBuyBlockItem } from '@/features/posts/api/HowToBuyAPI'
import { ReactComponent as DownloadIcon } from './i/download.svg'

type Props = {
  title: string
  blockItems: HowToBuyBlockItem[]
}

export const HowToBuyOrder: React.FC<Props> = function (props) {
  const { title, blockItems } = props

  return (
    <div className="htb-order">
      <div className="htb-order__cont">
        <h2 className="htb-order__title">{title}</h2>
        <div className="htb-order__items">
          {blockItems.map((bi, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}`} className="htb-order__item">
              <div
                className={`htb-order__item-number ${i === 0 ? 'first' : ''} ${
                  i === blockItems.length - 1 ? 'last' : ''
                }`}
              >
                <div className="htb-order__item-number-dot">
                  <b />
                  <i />
                  <div className="htb-order__item-number-val">0{i + 1}</div>
                </div>
              </div>

              <div className="htb-order__item-main">
                <div className="htb-order__item-text">
                  {bi.title && (
                    <h3 className="htb-order__item-title">{bi.title}</h3>
                  )}
                  {bi.content && (
                    <div
                      className="htb-order__item-content light-content-area"
                      dangerouslySetInnerHTML={{ __html: bi.content }}
                    />
                  )}
                </div>
                {bi.attachment && (
                  <a
                    href={bi.attachment}
                    target="_blank"
                    rel="noreferrer"
                    className="htb-order__item-attachment"
                  >
                    <div className="htb-order__item-attachment-i">
                      <DownloadIcon />
                    </div>
                    {bi.subtitle && (
                      <div className="htb-order__item-attachment-text">
                        {bi.subtitle}
                      </div>
                    )}
                  </a>
                )}
              </div>

              {bi.icon && (
                <div className="htb-order__item-icon">
                  <img src={bi.icon} alt="" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
