/**
 * @description The WindowPage component.
 */
import React, { useCallback, useEffect, useState } from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useParams } from 'react-router'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { windowPanoConf } from '@/features/info/windowPanoConf'
import { WindowPano } from '@/features/info/components/WindowPano'
import { useIsMobile } from '@/hooks/useIsMobile'

export const WindowPage: React.FC = function (props) {
  const goTo = useGoToWithLocale()
  const isMobile = useIsMobile()
  const [loaded, setLoaded] = useState(false)

  const { floor } = useParams<{ floor: string }>()
  const floorNumber = parseInt(floor, 10)
  const isWrongFloor = Number.isNaN(floorNumber)
  const panoConf = windowPanoConf.find((wc) => wc.floor === floorNumber)

  const handleLoaded = useCallback(() => setLoaded(true), [])

  useEffect(() => {
    if (isWrongFloor) {
      goTo('/')
    }
  }, [isWrongFloor, goTo])

  return (
    <PageLayout
      isLoading={!loaded}
      isLoadingBlocking={false}
      footer={!isMobile}
      footerProps={{
        variant: 'light',
      }}
      fullpage={true}
    >
      {!isWrongFloor && (
        <WindowPano
          floor={floorNumber}
          onLoaded={handleLoaded}
          url={`${panoConf?.src}pano.xml`}
        />
      )}
    </PageLayout>
  )
}
