import { api } from '@/services/api'

/* eslint-disable camelcase */

export type HowToBuy = {
  title: string
  blocks: {
    options: {
      title: string
      block_items: HowToBuyBlockItem[]
    }
    order: {
      title: string
      block_items: HowToBuyBlockItem[]
    }
    info: {
      title: string
      block_items: HowToBuyBlockItem[]
    }
  }
}

export type HowToBuyBlockItem = {
  title: string
  subtitle: string
  content: string
  photo: string | null
  icon: string | null
  attachment: string | null
}

export function getHowToBuy() {
  return api.get<HowToBuy>(
    '/how-to-buy/',
    {},
    {},
    {
      i18n: true,
    },
  )
}
