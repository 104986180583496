/**
 * @description The ApartmentsPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { Apartments } from '@/features/flats/components/Apartments/Apartments'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useFlatsData } from '@/features/flats/hooks/useFlatsData'

export const ApartmentsPage: React.FC = function (props) {
  const isMobile = useIsMobile()
  const { isLoading, isError, data } = useFlatsData()

  return (
    <PageLayout
      fullpage
      footer={!isMobile}
      isLoading={isLoading}
      isError={isError}
      footerProps={{ variant: 'light' }}
    >
      {data !== undefined && <Apartments flatManager={data} />}
    </PageLayout>
  )
}
