/**
 * @description The HowToBuyOptions component.
 */
import React from 'react'
import { HowToBuyBlockItem } from '@/features/posts/api/HowToBuyAPI'

type Props = {
  title: string
  blockItems: HowToBuyBlockItem[]
}

export const HowToBuyOptions: React.FC<Props> = function (props) {
  const { title, blockItems } = props

  return (
    <div className="htb-options">
      <div className="htb-options__cont">
        <h2 className="htb-options__title">{title}</h2>
        <div className="htb-options__items">
          {blockItems.map((bi, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${i}`} className="htb-options__item">
              <div className="htb-options__item-bg">
                {bi.photo && (
                  <img
                    src={bi.photo}
                    alt=""
                    className="htb-options__item-img"
                  />
                )}
              </div>
              <div className="htb-options__item-fg">
                {bi.title && (
                  <h3 className="htb-options__item-title">{bi.title}</h3>
                )}
                {bi.content && (
                  <div
                    className="htb-options__item-content light-content-area"
                    dangerouslySetInnerHTML={{ __html: bi.content }}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
