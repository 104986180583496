import { api } from '@/services/api'

export type DocumentsListItem = {
  id: number
  title: string
  file: string
  fileSize: string
  fileExt: string
  date: string
}

export function getDocuments() {
  return api.get<DocumentsListItem[]>(
    '/documents/',
    {},
    {
      transformResponse: (raw) =>
        JSON.parse(raw).map((doc: any) => ({
          id: doc.id,
          title: doc.title,
          file: doc.document_file,
          fileSize: doc.document_file_size,
          fileExt: doc.document_file_extension,
          date: doc.date_created,
        })),
    },
    {
      i18n: true,
    },
  )
}
