import { SimpleDuck } from '@creatorum/react-duck/dist/core/duckStack'
import locale from '@creatorum/react-ml/dist/locale.duck'
import { langDetector } from './services/langDetector'
import settings from './ducks/settings.duck'
import { ducks as info } from '@/features/info/ducks'
import { ducks as flats } from '@/features/flats/ducks'

// add lang detector
locale.sagas = [...locale.sagas, ...langDetector.sagas]

/**
 * Connect it here
 */
export const ducks: SimpleDuck[] = [locale, settings, ...info, ...flats]
