import { useCallback } from 'react'
import { IFlat } from '@/features/flats/services/flatManager'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'

export function useGoToFlat(isFromSearch = false) {
  const goTo = useGoToWithLocale()
  return useCallback(
    (flat: IFlat) =>
      goTo(
        `/apartments/floor-${flat.floorNumber}/flat-${flat.id}/`,
        isFromSearch
          ? {
              fromSearch: true,
            }
          : undefined,
      ),
    [goTo, isFromSearch],
  )
}
