import { api } from '@/services/api'

/* eslint-disable camelcase */

export type NewsListItem = {
  id: number
  slug: string
  title: string
  photo: string
  mobile_photo: string | null
  date_created: string
}

export type NewsItem = {
  id: number
  slug: string
  title: string
  content: string
  photo: string
  mobile_photo: string | null
  date_created: string
  gallery_photos: NewsGalleryPhoto[]
}

export type NewsGalleryPhoto = {
  photo: string
  mobile_photo: string | null
  alt: string
  date_created: string
}

export function getNewsList() {
  return api.get<NewsListItem[]>(
    '/news/',
    {},
    {},
    {
      i18n: true,
    },
  )
}

export function getNewsItem(slug: string) {
  return api.get<NewsItem>(
    `/news/${slug}/`,
    {},
    {},
    {
      i18n: true,
    },
  )
}
