import { StringStorage } from '@creatorum/react-ml/dist/stringStorage/StringStorage'
import { transformStringStorageItem } from '@creatorum/react-ml/dist/django/transformStringStorageItem'

import stringSources from '../stringSources'

const stringStorage = new StringStorage({
  transformItem(p: string, value: string, settings): string {
    return transformStringStorageItem(p, value, settings)
  },
})

stringStorage.addItems(stringSources)

export default stringStorage
