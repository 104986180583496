/**
 * @description The FloorLegend component.
 */
import React from 'react'
import './FloorLegend.scss'
import { ss } from '@/features/flats/stringSource'
import { T } from '@creatorum/react-ml/dist/components/T'

type Props = {
  sold: boolean
  available: boolean
}

export const FloorLegend: React.FC<Props> = function (props) {
  const { sold, available } = props

  return (
    <div className="floor-legend">
      <div className="floor-legend__cont">
        {sold && (
          <div className="floor-legend__row">
            <div className="floor-legend__dot" />
            <div className="floor-legend__label">
              <T p={ss.flats.floor.legend.sold} />
            </div>
          </div>
        )}
        {available && (
          <div className="floor-legend__row">
            <div className="floor-legend__dot floor-legend__dot--free" />
            <div className="floor-legend__label">
              <T p={ss.flats.floor.legend.available} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
