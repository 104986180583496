/**
 * @description The Menu component.
 */
import React, { useCallback } from 'react'
import { MenuItem, useMenuStructure } from '@/components/Menu/useMenuStructure'
import { T } from '@creatorum/react-ml/dist/components/T'
import { Link } from '@creatorum/react-ml/dist/components/Link'
import { Footer } from '@/components/Footer'
import './Menu.scss'
import bg from '@/images/bg-pattern.svg'
import { useMenuState } from '@/hooks/useMenuState'
import { makeMods } from '@creatorum/bem'

export const Menu: React.FC = function (props) {
  const structure = useMenuStructure()
  const [isMenuOpen] = useMenuState()

  const renderItem = useCallback((item: MenuItem) => {
    const commonProps = {
      className: 'menu__item',
      children: <T p={item.titleSrc} />,
    }

    if (item.as === 'button') {
      return <button type="button" {...commonProps} onClick={item.onClick} />
    }

    return <Link to={item.to as string} {...commonProps} />
  }, [])

  return (
    <div
      className={makeMods('menu', {
        open: isMenuOpen,
      })}
    >
      <div className="menu__cont">
        <div className="menu__bg">
          <img src={bg} alt="" />
        </div>
        <nav className="menu__nav">
          {structure.map((section) => (
            <div key={section.mainItem.key} className="menu__section">
              <div className="menu__section-main">
                {renderItem(section.mainItem)}
              </div>
              {section.subItems && (
                <div className="menu__section-sub">
                  {section.subItems.map((item) => (
                    <div key={item.key} className="menu__section-sub-item">
                      {renderItem(item)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>

        <div className="menu__footer">
          <Footer variant="light" />
        </div>
      </div>
    </div>
  )
}
