/**
 * @description The FeedbackForm component.
 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useStringStorage } from '@creatorum/react-ml/dist/hooks/useStringStorage'
import { ss } from '@/stringSources'
import { Button } from '@/components/Button'
import { useMutation } from 'react-query'
import {
  postFeedback,
  postBookingOrder,
  FeedbackParams,
} from '@/api/FeedbackAPI'
import { ReactComponent as SubmitIcon } from './i/submit-icon.svg'
import './FeedbackForm.scss'

type Props = {
  title: ReactNode
  description: ReactNode
  variant?: 'fullpage' | 'default'
}

type FormValues = {
  name: string
  phone: string
  message?: string
}

export const FeedbackForm: React.FC<Props> = function (props) {
  const { variant = 'default', title, description } = props
  const [sent, setSent] = useState(false)
  const stringSource = useStringStorage()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>()

  const request = useMutation(
    async (params: FeedbackParams) => {
      const res = await (variant === 'default'
        ? postFeedback
        : postBookingOrder)(params)
      return res.data
    },
    {
      onSuccess: () => {
        setSent(true)
        reset()
      },
    },
  )

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sent) {
      const t = setTimeout(() => {
        setSent(false)
      }, 7000)
      return () => {
        clearTimeout(t)
      }
    }
  }, [sent])

  const onSubmit = useCallback(
    (values: FormValues) => {
      request.mutate({
        ...values,
      })
    },
    [request],
  )

  const titleToUse = sent
    ? stringSource.get(ss.shared.form.result.success.title)
    : title
  const descriptionToUse = sent
    ? stringSource.get(ss.shared.form.result.success.description)
    : description

  return (
    <div className="ff">
      <div className="ff__cont">
        <h2 className="ff__title">{titleToUse}</h2>
        <div className="ff__description">{descriptionToUse}</div>

        {!sent && (
          <form onSubmit={handleSubmit(onSubmit)} className="ff__form">
            <div className="ff__row">
              <input
                disabled={request.isLoading}
                {...register('name', {
                  required: stringSource.get(
                    ss.shared.form.validation.required,
                  ),
                })}
                placeholder={stringSource.get(ss.shared.form.input.name)}
                type="text"
                className="ff__input"
              />
              {errors.name && (
                <div className="ff__input-error">{errors.name.message}</div>
              )}
            </div>

            <div className="ff__row">
              <input
                disabled={request.isLoading}
                {...register('phone', {
                  required: stringSource.get(
                    ss.shared.form.validation.required,
                  ),
                })}
                placeholder={stringSource.get(ss.shared.form.input.phone)}
                type="text"
                className="ff__input"
              />
              {errors.phone && (
                <div className="ff__input-error">{errors.phone.message}</div>
              )}
            </div>

            <div className="ff__row">
              <textarea
                disabled={request.isLoading}
                {...register('message', {
                  required: stringSource.get(
                    ss.shared.form.validation.required,
                  ),
                })}
                placeholder={stringSource.get(ss.shared.form.input.message)}
                className="ff__input"
              />
              {errors.message && (
                <div className="ff__input-error">{errors.message.message}</div>
              )}
            </div>

            <div className="ff__footer">
              {request.isError && (
                <div className="ff__errors">
                  {stringSource.get(ss.shared.form.result.error)}
                </div>
              )}
              <div className="ff__actions">
                <Button
                  disabled={request.isLoading}
                  type="submit"
                  className="ff__submit"
                  icon={<SubmitIcon />}
                >
                  {request.isLoading
                    ? stringSource.get(ss.shared.form.submitting)
                    : stringSource.get(ss.shared.form.submit)}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}
