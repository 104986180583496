/**
 * @description The Home component.
 */
import React, { useRef, useMemo, useState, useEffect, ReactPortal } from 'react'
import { createPortal } from 'react-dom'
import { usePanorama } from '@/hooks/usePanorama'
import './Home.scss'
import { mm } from '@creatorum/bem'
import { calcMouseShiftFromCenter } from '@/utils/calcMouseShiftFromCenter'
import { HomeHotspot } from '@/features/info/components/Home/HomeHotspot'
import { Footer } from '@/components/Footer'
import { useIsMobile } from '@/hooks/useIsMobile'
import { Button } from '@/components/Button'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '../../stringSource'
import { ReactComponent as EyeIcon } from './i/eye.svg'
import { ReactComponent as InfoIcon } from './i/info.svg'
import { ReactComponent as KeyIcon } from './i/key.svg'
import { ReactComponent as TourIcon } from './i/tour.svg'
import mapImage from './i/map.svg'
import { appConf } from '@/config'
import { useFlatsData } from '@/features/flats/hooks/useFlatsData'

export const Home: React.FC = function (props) {
  const [tiltPanShift, setTiltPanShift] = useState({
    tilt: 0,
    pan: 0,
  })
  const [tiltPanStatic, setTiltPanStatic] = useState({
    tilt: 0,
    pan: 0,
  })
  const [ready, setReady] = useState(false)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const isMobile = useIsMobile()
  const flatsData = useFlatsData()

  const playerInitialOptions = useRef({
    pan: 0,
    tilt: 0,
    fov: 0,
  })

  // prepare hotspots
  const hotspots = useMemo(
    () => [
      {
        id: 'tour',
        pan: -2.62,
        tilt: 32.16,
        domElement: (() => {
          const el = document.createElement('div')
          el.classList.add('home__pano-hp')
          el.innerHTML = '<div id="home-pano-hp-tour"></div>'
          return el
        })(),
      },
      {
        id: 'flats',
        pan: -339.08,
        tilt: 3.55,
        domElement: (() => {
          const el = document.createElement('div')
          el.classList.add('home__pano-hp')
          el.innerHTML = '<div id="home-pano-hp-flats"></div>'
          return el
        })(),
      },
      {
        id: 'highlights',
        pan: -13.1,
        tilt: 11.75,
        domElement: (() => {
          const el = document.createElement('div')
          el.classList.add('home__pano-hp')
          el.innerHTML = '<div id="home-pano-hp-highlights"></div>'
          return el
        })(),
      },
    ],
    [],
  )
  const rendererRef = useRef<HTMLDivElement>(null)
  const { loaded, pano } = usePanorama({
    element: rendererRef,
    panoramaURL: `${appConf.publicURL}/pano/home/pano.xml`,
    addSkin: false,
  })

  // set initial position, add hotspots
  useEffect(() => {
    if (!loaded) return
    const player = pano.current
    playerInitialOptions.current = {
      pan: player.getPan(),
      tilt: player.getTilt(),
      fov: player.getFov(),
    }
    setTiltPanStatic({
      pan: player.getPan(),
      tilt: player.getTilt(),
    })
    player.setTilt(30)
    hotspots.forEach((el) => {
      player.addHotspot(el.id, el.pan, el.tilt, el.domElement)
    })
    setReady(true)
  }, [hotspots, loaded, pano, setReady, setTiltPanStatic])

  // render hotspots via react components
  const renderHotspots = useMemo(() => {
    if (!ready) return null
    const out: ReactPortal[] = []
    const tourHP = document.getElementById('home-pano-hp-tour')
    if (tourHP)
      out.push(
        createPortal(
          <HomeHotspot
            to="/tour?start=1"
            icon={<TourIcon />}
            label={<T p={ss.info.home.hotspots.tour} />}
          />,
          tourHP,
        ),
      )
    const flatsHP = document.getElementById('home-pano-hp-flats')
    if (flatsHP)
      out.push(
        createPortal(
          <HomeHotspot
            to="/apartments"
            icon={<KeyIcon />}
            label={<T p={ss.info.home.hotspots.flats} />}
            sublabel={
              <T
                p={ss.info.home.hotspots.flatsCount}
                params={{
                  flats:
                    flatsData.data
                      ?.getAvailableFlats()
                      .filter((f) => f.isLiving).length || 0,
                }}
              />
            }
          />,
          flatsHP,
        ),
      )
    const highlightsHP = document.getElementById('home-pano-hp-highlights')
    if (highlightsHP)
      out.push(
        createPortal(
          <HomeHotspot
            to="/characteristics"
            icon={<InfoIcon />}
            label={<T p={ss.info.home.hotspots.chars} />}
          />,
          highlightsHP,
        ),
      )
    return out
  }, [flatsData.data, ready])

  // start animation
  useEffect(() => {
    if (!(ready && started)) return
    const player = pano.current
    const initialOpts = playerInitialOptions.current

    const targetTilt = initialOpts.tilt
    const tiltPerFrame = 1

    player.moveTo(player.getPan(), targetTilt, player.getFov(), tiltPerFrame)
    const interval = setInterval(() => {
      if (!player.u.active) {
        setFinished(true)
        clearInterval(interval)
      }
    }, 25)

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(interval)
      try {
        player.stopAutorotate()
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  }, [ready, started, setFinished, pano])

  // watch mouse cursor and calc camera shift
  useEffect(() => {
    if (isMobile) return
    const handler = ({ x: originX, y: originY }: MouseEvent) => {
      const { hor, vert } = calcMouseShiftFromCenter(
        { x: originX, y: originY },
        { width: window.innerWidth, height: window.innerHeight },
        { left: 30, right: 30, top: 10, bottom: 20 },
      )
      setTiltPanShift({
        tilt: vert,
        pan: hor,
      })
    }
    window.addEventListener('mousemove', handler)
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('mousemove', handler)
  }, [isMobile, setTiltPanShift])

  // animate on mouse move
  useEffect(() => {
    if (!finished) return
    const player = pano.current
    const tilt = tiltPanStatic.tilt + tiltPanShift.tilt * -1
    const pan = tiltPanStatic.pan + tiltPanShift.pan * -1
    player.moveTo(pan, tilt, player.getFov(), 5)
  }, [tiltPanShift, tiltPanStatic, finished, pano])

  return (
    <div
      className={mm('home', {
        started,
        loaded,
        ready,
        finished,
      })}
    >
      <div className="home__cont">
        <div className="home__pano" ref={rendererRef} id="home-pano-renderer" />
        {renderHotspots}

        {!isMobile && (
          <div className="home__footer">
            <Footer variant="light" />
          </div>
        )}

        <div className="home__map">
          <img src={mapImage} alt="" />
        </div>

        <div className="home__start">
          <Button onClick={() => setStarted(true)} icon={<EyeIcon />}>
            <T p={ss.info.home.start} />
          </Button>
        </div>
      </div>
    </div>
  )
}
