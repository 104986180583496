/**
 * @description The DocumentsList component.
 */
import React from 'react'
import './DocumentsList.scss'
import { DocumentsListItem } from '@/features/posts/api/DocumentsAPI'
import { DocumentsListEl } from '@/features/posts/components/DocumentsList/DocumentsListEl'

type Props = {
  items: DocumentsListItem[]
}

export const DocumentsList: React.FC<Props> = function (props) {
  const { items } = props

  return (
    <div className="docs-list">
      <div className="docs-list__cont">
        {items.map((doc) => (
          <div key={doc.id} className="docs-list__el">
            <DocumentsListEl item={doc} />
          </div>
        ))}
      </div>
    </div>
  )
}
