/**
 * @description The NewsListItem component.
 */
import React from 'react'
import { NewsListItem as TNewsListItem } from '@/features/posts/api/NewsAPI'
import { Link } from '@creatorum/react-ml/dist/components/Link'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/posts/stringSource'
import { ReactComponent as GoIcon } from './i/go.svg'
import defaultPhotoImg from './i/default-photo.jpeg'
import { useIsMobile } from '@/hooks/useIsMobile'
import { DateView } from '@/components/DateView/DateView'

/* eslint-disable camelcase */

type Props = TNewsListItem

export const NewsListItem: React.FC<Props> = function (props) {
  const { title, slug, photo, mobile_photo, date_created } = props
  const isMobile = useIsMobile()

  const photoToUse =
    (isMobile ? mobile_photo || photo : photo) || defaultPhotoImg

  return (
    <div className="news-list-el">
      <div className="news-list-el__cont">
        <div className="news-list-el__photo">
          <img src={photoToUse} alt={title} />
        </div>

        <div className="news-list-el__content">
          <div className="news-list-el__date">
            <DateView date={date_created} />
          </div>
          <h2 className="news-list-el__title">{title}</h2>
          <div className="news-list-el__link-cont">
            <Link to={`/news/${slug}/`} className="news-list-el__link">
              <T p={ss.posts.news.readMore} />
              <i>
                <GoIcon />
              </i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
