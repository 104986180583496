/**
 * @description The ApartmentsFloorPanel component.
 */
import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import './ApartmentsFloorPanel.scss'
import { Maybe } from '@/types'
import { useIsMobile } from '@/hooks/useIsMobile'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/flats/stringSource'
import { Link } from '@creatorum/react-ml/dist/components/Link'
import { useRem } from '@/hooks/useRem'
import { ReactComponent as GoIcon } from './i/go.svg'

type Props = {
  floorNumber: Maybe<number>
  targetBCR: Maybe<DOMRect>
  flats: number
  isFlat?: boolean
}

export const ApartmentsFloorPanel: React.FC<Props> = function (props) {
  const { floorNumber, targetBCR, flats, isFlat } = props
  const bcrChangesCountRef = useRef(0)
  const rootRef = useRef<Maybe<HTMLDivElement>>(null)
  const isMobile = useIsMobile()
  const [styles, setStyles] = useState<CSSProperties>({})
  const { rem } = useRem()

  useEffect(() => {
    bcrChangesCountRef.current += 1
  }, [targetBCR])

  useEffect(() => {
    if (!rootRef.current || !targetBCR) return

    const { height } = rootRef.current.getBoundingClientRect()
    let left = 0
    let top = 0
    const additionalStyles: CSSProperties = {}

    // eslint-disable-next-line no-empty
    if (isMobile) {
      additionalStyles.transform = 'translate(-50%, 0)'
      additionalStyles.left = '50%'
      if (
        window.innerHeight - (targetBCR.y + targetBCR.height) >=
        height + rem(20)
      ) {
        top = targetBCR.y + targetBCR.height + rem(20)
      } else {
        top = targetBCR.y - height - rem(20)
      }
    } else {
      left = targetBCR.x + targetBCR.width + rem(30)
      top = targetBCR.y + targetBCR.height / 2 - height / 2
      if (top <= rem(150)) {
        top = rem(150)
      }
      if (top + height >= window.innerHeight - rem(100)) {
        top = window.innerHeight - rem(100) - height
      }
    }

    setStyles({
      left: `${left}px`,
      top: `${top}px`,
      ...additionalStyles,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    floorNumber,
    targetBCR?.x,
    targetBCR?.y,
    targetBCR?.width,
    targetBCR?.height,
    isMobile,
    rem,
  ])

  return (
    <div
      ref={rootRef}
      className="apartments-fp"
      style={{
        ...styles,
        ...(bcrChangesCountRef.current <= 2
          ? {
              transition: 'none',
            }
          : {}),
      }}
    >
      <div className="apartments-fp__cont">
        <div className="apartments-fp__floor">
          <T
            p={ss.flats.apartments.floorPanel.floor}
            params={{ floor: floorNumber }}
          />
        </div>
        <div className="apartments-fp__flats">
          <T
            p={
              isFlat
                ? ss.flats.apartments.floorPanel.flatsAvailable
                : ss.flats.apartments.floorPanel.officesAvailable
            }
            params={{ flats }}
          />
        </div>
        {(isMobile || true) && (
          <Link
            to={`/apartments/floor-${floorNumber}`}
            className="apartments-fp__link"
          >
            <T p={ss.flats.apartments.floorPanel.go} />
            <GoIcon />
          </Link>
        )}
      </div>
    </div>
  )
}
