import { appConf } from '@/config'

type WindowPanoItem = {
  floor: number
  src: string
}

export const windowPanoConf: WindowPanoItem[] = [
  {
    floor: 3,
    src: `${appConf.windowURL}/9m/`,
  },
  {
    floor: 4,
    src: `${appConf.windowURL}/12m/`,
  },
  {
    floor: 5,
    src: `${appConf.windowURL}/15m/`,
  },
  {
    floor: 6,
    src: `${appConf.windowURL}/17.5m/`,
  },
  {
    floor: 7,
    src: `${appConf.windowURL}/20.5m/`,
  },
  {
    floor: 8,
    src: `${appConf.windowURL}/23m/`,
  },
  {
    floor: 9,
    src: `${appConf.windowURL}/26m/`,
  },
  {
    floor: 10,
    src: `${appConf.windowURL}/29m/`,
  },
  {
    floor: 11,
    src: `${appConf.windowURL}/32m/`,
  },
  {
    floor: 12,
    src: `${appConf.windowURL}/34.5m/`,
  },
  {
    floor: 13,
    src: `${appConf.windowURL}/37.5m/`,
  },
  {
    floor: 14,
    src: `${appConf.windowURL}/40.5m/`,
  },
  {
    floor: 15,
    src: `${appConf.windowURL}/43m/`,
  },
  {
    floor: 16,
    src: `${appConf.windowURL}/46m/`,
  },
  {
    floor: 17,
    src: `${appConf.windowURL}/49m/`,
  },
  {
    floor: 18,
    src: `${appConf.windowURL}/52m/`,
  },
  {
    floor: 19,
    src: `${appConf.windowURL}/54.5m/`,
  },
  {
    floor: 20,
    src: `${appConf.windowURL}/57.5m/`,
  },
  {
    floor: 21,
    src: `${appConf.windowURL}/60m/`,
  },
  {
    floor: 22,
    src: `${appConf.windowURL}/63m/`,
  },
  {
    floor: 23,
    src: `${appConf.windowURL}/66m/`,
  },
  {
    floor: 24,
    src: `${appConf.windowURL}/70m/`,
  },
]
