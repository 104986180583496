/**
 * @description The FloorNav component.
 */
import React, { useCallback } from 'react'
import { FlatManager } from '@/features/flats/services/flatManager'
import { useFloorNav } from '@/features/flats/hooks/useFloorNav'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { FloorNav as FN } from '@/components/FloorNav'

type Props = {
  floorNumber: number
  flatManager: FlatManager
}

export const FloorNav: React.FC<Props> = function (props) {
  const { floorNumber, flatManager } = props
  const { current, prev, next } = useFloorNav(floorNumber, flatManager)
  const goTo = useGoToWithLocale()

  const goToFloor = useCallback(
    (n: number) => {
      goTo(`/apartments/floor-${n}`)
    },
    [goTo],
  )

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <FN
      onNext={() => (next ? goToFloor(next) : null)}
      onPrev={() => (prev ? goToFloor(prev) : null)}
      current={current}
    />
  )
}
