import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'

export function useGoBack() {
  const history = useHistory()
  const goTo = useGoToWithLocale()

  return useCallback(
    (defaultTo = '/') => {
      if (history.length) {
        history.goBack()
        return
      }
      goTo(defaultTo)
    },
    [goTo, history],
  )
}
