/**
 * @description The TeamList component.
 */
import React, { useMemo, useState } from 'react'
import './TeamList.scss'
import { Team } from '@/features/info/api/TeamAPI'
import { makeMods } from '@creatorum/bem'

type Props = {
  teams: Team[]
}

export const TeamList: React.FC<Props> = function (props) {
  const { teams } = props
  const [tab, setTab] = useState(0)
  const currentTeam = useMemo(
    () => teams.find((t, i) => i === tab),
    [teams, tab],
  ) as Team

  return (
    <div className="teams">
      <div className="teams__cont">
        <div className="teams__nav">
          <div className="teams__nav-cont">
            {teams.map((team, i) => (
              <button
                type="button"
                key={team.id}
                className={makeMods('teams__nav-el', {
                  active: tab === i,
                })}
                onClick={() => setTab(i)}
              >
                {team.title}
              </button>
            ))}
          </div>
        </div>
        <div className="teams__current">
          <div className="teams__media">
            <div className="teams__logo">
              <img src={currentTeam.logo} alt={currentTeam.site_title} />
            </div>
          </div>
          <div className="teams__text">
            <h2 className="teams__title">{currentTeam.site_title}</h2>
            <div
              className="teams__body content-area"
              dangerouslySetInnerHTML={{ __html: currentTeam.content }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
