import { RenderConfig } from '@creatorum/react-router-helper/dist/route'
import { routesRender as info } from '@/features/info/routesToRender'
import { routesRender as posts } from '@/features/posts/routesToRender'
import { routesRender as flats } from '@/features/flats/routesToRender'
import { appConf } from '@/config'

const toRender: RenderConfig[] = [...info, ...posts, ...flats]

// normalize trailing slash and add locale path param
toRender.forEach((el) => {
  el.normalizeTrailingSlash(appConf.router.useTrailingSlash)
  if (appConf.ml.enabled)
    el.path = `/:${appConf.ml.detector.routerLangParam}${el.path}`
})

export const routesRender = toRender
