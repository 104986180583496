import APIService from '@/libs/APIService/APIService'
import { appConf } from '@/config'
import { preloadImagesSet } from '@creatorum/utils/dist/preload/image'
import { langDetector } from '@/services/langDetector'

export const api = new APIService({
  baseURL: appConf.api.baseURL,
  imagesPreloadingHandler: (src: string[]) =>
    new Promise<HTMLImageElement[]>((resolve) => {
      preloadImagesSet(src).then(resolve).catch(resolve)
    }),
  i18n: {
    isUseByDefault: true,
    getHeaders() {
      return (
        langDetector.locale
          ? {
              'Accept-Language': langDetector.locale,
            }
          : {}
      ) as Record<string, string>
    },
  },
})

export default api
