/**
 * @description The DocumentsPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useNewsList } from '@/features/posts/hooks/useNewsList'
import { NewsList } from '@/features/posts/components/NewsList'
import { ss } from '@/features/posts/stringSource'

export const NewsPage: React.FC = function (props) {
  const news = useNewsList()

  // todo add background
  return (
    <PageLayout
      title={ss.posts.news.title}
      isLoading={news.isLoading}
      isError={news.isError}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <NewsList items={news.data || []} />
    </PageLayout>
  )
}
