/**
 * @description The FloorPlan component.
 */
import React, { useMemo } from 'react'
import { floorPlansConf } from '@/features/flats/floor-plans/conf'
import './FloorPlan.scss'
import { FlatManager } from '@/features/flats/services/flatManager'
import { useTranslate } from '@/hooks/useTranslate'
import { useGoToFlat } from '@/features/flats/hooks/useGoToFlat'

import { ss } from '@/features/flats/stringSource'
import { Maybe } from '@/types'

type Props = {
  floorNumber: number
  flatManager: FlatManager
  hover: Maybe<string>
  onHover: (h: Maybe<string>) => void
}

export const FloorPlan: React.FC<Props> = function (props) {
  const { floorNumber, flatManager, hover, onHover } = props
  const { bgSrc, selections } = floorPlansConf[floorNumber]
  const t = useTranslate()
  const soldString = t(ss.flats.flat.status.sold)
  const goToFlat = useGoToFlat()

  const renderFlatBase = (sc: typeof selections[0]) => {
    const flat = flatManager.getFlatByPlanCode(floorNumber, sc.flatType)
    if (!flat) {
      return null
    }
    const { isAvailable } = flat
    const isHover = hover === sc.flatType

    return (
      <g key={sc.flatType}>
        <path
          d={sc.path}
          fill={isHover ? '#AF3500' : '#D06300'}
          opacity={
            /* eslint-disable-next-line no-nested-ternary */
            isAvailable ? (isHover ? 0.3 : 0.2) : 0
          }
          style={{
            transition: 'all 300ms',
          }}
        />
        {isAvailable ? (
          <>
            <rect
              rx={isHover ? 35 : 17}
              width={isHover ? 70 : sc.bgBBox.width}
              height={isHover ? 70 : sc.bgBBox.height}
              x={sc.bgBBox.cx - (isHover ? 70 : sc.bgBBox.width) / 2}
              y={sc.bgBBox.cy - (isHover ? 70 : sc.bgBBox.height) / 2}
              fill="#fff"
              style={{
                transition: 'all 300ms',
              }}
            />
            <text
              x={sc.bgBBox.cx}
              y={sc.bgBBox.cy + 1}
              alignmentBaseline="middle"
              textAnchor="middle"
              fontSize="12"
              fontFamily="Nunito"
              fontWeight="700"
              fill="#000710"
            >
              {t(flat.isLiving ? ss.flats.flat.type : ss.flats.flat.roomType, {
                type: sc.flatType,
              })}
            </text>
          </>
        ) : (
          <>
            <rect
              rx="17"
              width={72}
              height={sc.bgBBox.height}
              x={sc.bgBBox.cx - 72 / 2}
              y={sc.bgBBox.cy - sc.bgBBox.height / 2}
              fill="#fff"
            />
            <text
              x={sc.bgBBox.cx}
              y={sc.bgBBox.cy + 1}
              alignmentBaseline="middle"
              textAnchor="middle"
              fontSize="12"
              fontFamily="Nunito"
              fontWeight="700"
              fill="#707986"
            >
              {soldString}
            </text>
          </>
        )}
      </g>
    )
  }

  const renderFlatEventListeners = (sc: typeof selections[0]) => {
    const flat = flatManager.getFlatByPlanCode(floorNumber, sc.flatType)
    if (!flat) {
      return null
    }
    const { isAvailable, isLiving } = flat
    if (!isAvailable) {
      return null
    }

    return (
      <g key={sc.flatType}>
        <path
          d={sc.path}
          fill="#fff"
          opacity={0}
          onMouseEnter={() => onHover(sc.flatType)}
          onMouseLeave={() => onHover(null)}
          onClick={() => (isLiving ? goToFlat(flat) : null)}
          style={{
            cursor: isLiving ? 'pointer' : 'default',
          }}
        />
      </g>
    )
  }

  const svgProps = useMemo(
    () =>
      floorNumber === 1
        ? {
            width: 1210,
            height: 760,
          }
        : {
            width: 760,
            height: 760,
          },
    [floorNumber],
  )

  const imgProps = useMemo(
    () =>
      floorNumber === 1
        ? {
            width: 2420,
            height: 1520,
            transform: 'matrix(0.5 0 0 0.5 0 0)',
          }
        : {
            width: 760,
            height: 760,
          },
    [floorNumber],
  )

  return (
    <div className="floor-plan">
      <div className="floor-plan__const">
        <svg
          className="floor-plan__svg"
          width={svgProps.width}
          height={svgProps.height}
          viewBox={`0 0 ${svgProps.width} ${svgProps.height}`}
        >
          <image
            width={imgProps.width}
            height={imgProps.height}
            x={0}
            y={0}
            transform={imgProps.transform}
            xlinkHref={bgSrc}
          />
          <g>{selections.map((sc) => renderFlatBase(sc))}</g>
          <g>{selections.map((sc) => renderFlatEventListeners(sc))}</g>
        </svg>
      </div>
    </div>
  )
}
