/**
 * @description The Tour component.
 */
import React, { RefObject } from 'react'
import './Tour.scss'

type Props = {
  rendererRef: RefObject<HTMLDivElement>
}

export const Tour: React.FC<Props> = function (props) {
  const { rendererRef } = props

  return (
    <div className="tour">
      <div className="tour__cont">
        <div id="tour-renderer" className="tour__renderer" ref={rendererRef} />
      </div>
    </div>
  )
}
