/**
 * @description The FlatView component.
 */
import React from 'react'
import { FlatManager, IFlat } from '@/features/flats/services/flatManager'
import { BackButton } from '@/components/BackButton'
import { FlatPanel } from '@/features/flats/components/FlatPanel'
import { Compass } from '@/features/flats/components/Compass'
import { FloorMini } from '@/features/flats/components/FloorMini'
import { ReactComponent as DownloadIcon } from './i/download.svg'
import { Button } from '@/components/Button'
import { ss } from '@/features/flats/stringSource'
import { T } from '@creatorum/react-ml/dist/components/T'
import './FlatView.scss'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useGoBack } from '@/hooks/useGoBack'
import { ReactComponent as BackIcon } from './i/back.svg'

type Props = {
  floorNumber: number
  flatId: number
  flatManager: FlatManager
}

export const FlatView: React.FC<Props> = function (props) {
  const { flatManager, floorNumber, flatId } = props
  const flat = flatManager.getFlatByID(flatId) as IFlat
  const isMobile = useIsMobile()
  const goBack = useGoBack()

  const renderPlan = () => (
    <div className="flat-view__plan">
      <img src={flat.urlPlanSVG} alt="" />
    </div>
  )

  const renderButtons = () => (
    <div className="flat-view__info-bottom">
      <Button
        as="link"
        href={flat.urlPlanPDF}
        target="_blank"
        className="flat-view__info-button"
        icon={<DownloadIcon />}
      >
        <T p={ss.flats.flat.pdf} />
      </Button>
    </div>
  )

  return (
    <div className="flat-view">
      <div className="flat-view__cont">
        {isMobile && renderPlan()}
        <div className="flat-view__info">
          <div className="flat-view__info-top">
            <div className="flat-view__info-back">
              <BackButton defaultTo="/apartments" />
            </div>
            <div className="flat-view__info-flat">
              <FlatPanel detailed flat={flat} />
            </div>
          </div>
          {!isMobile && renderButtons()}
        </div>
        {!isMobile && renderPlan()}
        <div className="flat-view__nav">
          <div className="flat-view__nav-compass">
            <Compass />
          </div>
          <div className="flat-view__nav-mini">
            <FloorMini floorNumber={floorNumber} flatType={flat.planCode} />
          </div>
        </div>
        {isMobile && renderButtons()}
        {isMobile && (
          <button type="button" onClick={goBack} className="flat-view__back">
            <BackIcon />
          </button>
        )}
        <div className="flat-view__notice">
          <div className="flat-view__notice-cont">
            <T html={true} p={ss.flats.flat.priceCurrencyExchangeNotice} />
          </div>
        </div>
      </div>
    </div>
  )
}
