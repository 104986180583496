import { useQuery } from 'react-query'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { getNewsList } from '@/features/posts/api/NewsAPI'

export function useNewsList() {
  const locale = useLocale()
  return useQuery(['news', locale], async () => {
    const res = await getNewsList()
    return res.data
  })
}
