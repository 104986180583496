/**
 * @description The Page404 component.
 */
import React from 'react'
import { PageNotFound } from '@/components/PageNotFound'
import { PageLayout } from '@/components/PageLayout'

export const Page404: React.FC = function (props) {
  // todo add bg
  return (
    <PageLayout
      fullpage={true}
      footer={true}
      footerProps={{ variant: 'light' }}
    >
      <PageNotFound />
    </PageLayout>
  )
}
