import { Config } from '@creatorum/react-router-helper/dist/route'
import { appConf } from './config'
import { routes as info } from '@/features/info/routes'
import { routes as posts } from '@/features/posts/routes'
import { routes as flats } from '@/features/flats/routes'

const allRoutes: Config[] = [...info, ...posts, ...flats]

export const routesToKeyMap: { [p: string]: string } = {}
allRoutes.forEach((el) => {
  routesToKeyMap[el.id] = el.path
})

// normalize trailing slash and add locale path param
allRoutes.forEach((el) => {
  el.normalizeTrailingSlash(appConf.router.useTrailingSlash)
  if (appConf.ml.enabled)
    el.path = `/:${appConf.ml.detector.routerLangParam}${el.path}`
})

export const routes: Config[] = allRoutes
