/**
 * @description The ConstructionListTimeline component.
 */
import React, { useMemo } from 'react'
import { ConstructionListItem } from '@/features/posts/api/ConstructionAPI'
import { format } from 'date-fns'
import { DateView } from '@/components/DateView/DateView'

type Props = {
  items: ConstructionListItem[]
}

type TLItem = {
  isFirst: boolean
  showYear: boolean
  item: ConstructionListItem
}

export const ConstructionListTimeline: React.FC<Props> = function (props) {
  const { items } = props

  const tlItems: TLItem[] = useMemo(
    () =>
      items.map((item, i) => ({
        isFirst:
          i === 0
            ? true
            : format(new Date(item.date_created), 'yyyy-MM') !==
              format(new Date(items[i - 1].date_created), 'yyyy-MM'),
        showYear:
          format(new Date(item.date_created), 'yyyy') !==
          format(new Date(), 'yyyy'),
        item,
      })),
    [items],
  )

  return (
    <div className="cd-tl">
      <div className="cd-tl__cont">
        {tlItems.map((tli) => (
          <div key={tli.item.id} className="cd-tl__item">
            <div className="cd-tl__item-dot" />
            {tli.isFirst && (
              <div className="cd-tl__item-text">
                <DateView
                  className="cd-tl__item-month"
                  date={tli.item.date_created}
                  format="LLLL"
                />
                {tli.showYear && (
                  <DateView
                    className="cd-tl__item-year"
                    date={tli.item.date_created}
                    format="yyyy"
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
