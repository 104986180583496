/**
 * @description The WindowPano component.
 */
import React, { useMemo } from 'react'
import { Panorama } from '@/components/Panorama'
import './WindowPano.scss'
import { windowPanoConf } from '@/features/info/windowPanoConf'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { FloorNav } from '@/components/FloorNav'

type Props = {
  onLoaded: () => void
  url: string
  floor: number
}

export const WindowPano: React.FC<Props> = function (props) {
  const { onLoaded, url, floor } = props
  const goTo = useGoToWithLocale()
  const currentItemIndex = useMemo(
    () => windowPanoConf.findIndex((wc) => wc.floor === floor),
    [floor],
  )
  const prevItem = useMemo(() => {
    if (currentItemIndex === 0) {
      return windowPanoConf[windowPanoConf.length - 1]
    }
    return windowPanoConf[currentItemIndex - 1]
  }, [currentItemIndex])
  const nextItem = useMemo(() => {
    if (currentItemIndex === windowPanoConf.length - 1) {
      return windowPanoConf[0]
    }
    return windowPanoConf[currentItemIndex + 1]
  }, [currentItemIndex])

  return (
    <div className="window-pano">
      <div className="window-pano__cont">
        <div className="window-pano__main">
          <div className="window-pano__pano">
            <Panorama onLoaded={onLoaded} url={url} />
          </div>
          <div className="window-pano__over" />
        </div>
        <div className="window-pano__nav">
          <FloorNav
            current={floor}
            onNext={() => goTo(`/window/${nextItem.floor}`)}
            onPrev={() => goTo(`/window/${prevItem.floor}`)}
          />
        </div>
      </div>
    </div>
  )
}
