/**
 * @description The NewsItem component.
 */
import React, { useMemo } from 'react'
import './NewsItem.scss'
import { NewsItem as TNewsItem } from '@/features/posts/api/NewsAPI'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useGoBack } from '@/hooks/useGoBack'
import { DateView } from '@/components/DateView/DateView'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/posts/stringSource'
import { ReactComponent as BackIcon } from './i/back.svg'
import { ContentArea } from '@/components/ContentArea/ContentArea'
import { SocialSticky } from '@/components/SocialSticky/SocialSticky'
import Sticky from 'react-stickynode'
import { useRem } from '@/hooks/useRem'
import {
  EmbeddedGallery,
  EmbeddedGalleryPhoto,
} from '@/components/EmbeddedGallery/EmbeddedGallery'

/* eslint-disable camelcase */

type Props = {
  data: TNewsItem
}

export const NewsItem: React.FC<Props> = function (props) {
  const {
    data: { photo, content, date_created, gallery_photos, mobile_photo, title },
  } = props

  const isMobile = useIsMobile()
  const goBack = useGoBack()
  const { rem } = useRem()
  const galleryPhotos: EmbeddedGalleryPhoto[] = useMemo(
    () =>
      gallery_photos.map((p) => ({
        alt: p.alt || undefined,
        src: p.photo,
        srcMobile: p.mobile_photo || undefined,
      })),
    [gallery_photos],
  )

  return (
    <div className="news-item">
      <div className="news-item__cont">
        <div className="news-item__photo">
          <img
            src={isMobile && mobile_photo ? mobile_photo : photo}
            alt={title}
          />
        </div>

        <div className="news-item__nav">
          <button
            type="button"
            className="news-item__back"
            onClick={() => goBack('/news')}
          >
            <i>
              <BackIcon />
            </i>
            <T p={ss.posts.newsItem.back} />
          </button>
          <div className="news-item__date">
            <DateView date={date_created} />
          </div>
        </div>

        <h1 className="news-item__title">{title}</h1>

        <div className="news-item__main">
          <div className="news-item__content">
            <ContentArea
              className="news-item__content-text"
              content={content}
            />
            {gallery_photos.length > 0 && (
              <div className="news-item__gallery">
                <EmbeddedGallery photos={galleryPhotos} modal={true} />
              </div>
            )}
            <div className="news-item__nav news-item__nav--bottom">
              <button
                type="button"
                className="news-item__back"
                onClick={() => goBack('/news')}
              >
                <i>
                  <BackIcon />
                </i>
                <T p={ss.posts.newsItem.back} />
              </button>
              <div className="news-item__date" />
            </div>
          </div>
          <div className="news-item__sidebar">
            <Sticky enabled={!isMobile} top={rem(250)}>
              <SocialSticky />
            </Sticky>
          </div>
        </div>
      </div>
    </div>
  )
}
