export const EVENT_LOADING_DONE = 'marshall/loadingDone'
export const EVENT_LOADER_HIDDEN = 'marshall/loaderHidden'

export function emitLoadingDone() {
  window.dispatchEvent(
    new CustomEvent(EVENT_LOADING_DONE, {
      detail: {
        react: true,
      },
    }),
  )
}
