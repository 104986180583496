/**
 * @description The SocialSticky component.
 */
import React from 'react'
import { useServerSettings } from '@/hooks/useServerSettings'
import { ReactComponent as FBIcon } from '@/images/social-fb.svg'
import { ReactComponent as InstaIcon } from '@/images/social-insta.svg'
import './SocialSticky.scss'

export const SocialSticky: React.FC = function () {
  const { instagram, facebook } = useServerSettings()

  return (
    <div className="social-sticky">
      <div className="social-sticky__content">
        <div className="social-sticky__social">
          <a
            href={facebook}
            target="_blank"
            rel="noreferrer"
            className="social-sticky__social-el"
          >
            <FBIcon />
          </a>
          <a
            href={instagram}
            target="_blank"
            rel="noreferrer"
            className="social-sticky__social-el"
          >
            <InstaIcon />
          </a>
        </div>
      </div>
    </div>
  )
}
