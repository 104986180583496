/**
 * @description The Header component.
 */
import React from 'react'
import { useServerSettings } from '@/hooks/useServerSettings'
import { ReactComponent as CallIcon } from '@/images/call.svg'
import { ReactComponent as LogoIcon } from '@/images/logo.svg'
import { ReactComponent as BurgerIcon } from './i/burger.svg'
import { ReactComponent as CloseIcon } from './i/close.svg'
import { ReactComponent as KeyIcon } from './i/key.svg'
import { ReactComponent as SearchIcon } from './i/search.svg'
import { useMenuState } from '@/hooks/useMenuState'
import { makeMods } from '@creatorum/bem'
import './Header.scss'
import { Link } from '@creatorum/react-ml/dist/components/Link'
import { T } from '@creatorum/react-ml/dist/components/T'
import { Button } from '@/components/Button'
import { ss } from '@/stringSources'
import { LangSwitcher } from '@/components/LangSwitcher'

export const Header: React.FC = function (props) {
  const { phone } = useServerSettings()
  const [menuOpen, setMenuOpen] = useMenuState()

  return (
    <div className="header">
      <div className="header__cont">
        <div className="header__left">
          <Link to="/" className="header__logo">
            <LogoIcon />
          </Link>

          <div className="header__lang">
            <LangSwitcher />
          </div>
        </div>

        <div className="header__center">
          <Button
            className="header__button-apartments"
            as="routerLink"
            to="/apartments"
            mobileIconOnly={true}
            icon={<KeyIcon />}
          >
            <T p={ss.shared.header.chooseAnApartments} />
          </Button>
          <Button
            className="header__button-search"
            as="routerLink"
            to="/apartments/search"
            mobileIconOnly={true}
            icon={<SearchIcon />}
            style={{ display: 'none' }}
          >
            <T p={ss.shared.header.searchAnApartments} />
          </Button>
        </div>

        <div className="header__right">
          <a href={`tel:${phone}`} className="header__phone">
            <div className="header__phone-i">
              <CallIcon />
            </div>
            <div className="header__phone-text">{phone}</div>
          </a>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            type="button"
            className={makeMods('header__burger', {
              open: menuOpen,
            })}
          >
            <span className="header__burger-i header__burger-i--burger">
              <BurgerIcon />
            </span>
            <span className="header__burger-i header__burger-i--close">
              <CloseIcon />
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}
