/**
 * @description The FloorView component.
 */
import React, { useMemo } from 'react'
import { FloorPlan } from '@/features/flats/components/FloorView/FloorPlan'
import './FloorView.scss'
import { FlatManager } from '@/features/flats/services/flatManager'
import { useHoverState } from '@/hooks/useHoverState'
import { FlatPanel } from '@/features/flats/components/FlatPanel'
import { FloorLegend } from '@/features/flats/components/FloorLegend'
import { BackButton } from '@/components/BackButton'
import { FloorMini } from '@/features/flats/components/FloorMini'
import { Compass } from '@/features/flats/components/Compass'
import { FloorNav } from '@/features/flats/components/FloorNav'

type Props = {
  floorNumber: number
  flatManager: FlatManager
}

export const FloorView: React.FC<Props> = function (props) {
  const { flatManager, floorNumber } = props
  const { latestHover, setHover } = useHoverState<string>()
  const hoveredFlat = useMemo(
    () => flatManager.getFlatByPlanCode(floorNumber, latestHover || ''),
    [flatManager, floorNumber, latestHover],
  )
  const count = useMemo(
    () =>
      flatManager.getFloorFlats(floorNumber).reduce(
        (acc, el) => {
          if (el.isAvailable) {
            // eslint-disable-next-line no-param-reassign
            acc.available += 1
          } else {
            // eslint-disable-next-line no-param-reassign
            acc.sold += 1
          }
          return { ...acc }
        },
        { sold: 0, available: 0 },
      ),
    [flatManager, floorNumber],
  )

  return (
    <div className="floor-view">
      <div className="floor-view__cont">
        <div className="floor-view__info">
          <div className="floor-view__info-top">
            <div className="floor-view__info-back">
              <BackButton defaultTo="/apartments" />
            </div>
            <div className="floor-view__info-flat">
              {hoveredFlat && <FlatPanel flat={hoveredFlat} />}
            </div>
          </div>
          <div className="floor-view__info-bottom">
            <div className="floor-view__info-legend">
              <FloorLegend
                sold={count.sold > 0}
                available={count.available > 0}
              />
            </div>
          </div>
        </div>
        <div className="floor-view__plan">
          <FloorPlan
            key={floorNumber}
            floorNumber={floorNumber}
            flatManager={flatManager}
            hover={latestHover}
            onHover={setHover}
          />
        </div>
        <div className="floor-view__nav">
          <div className="floor-view__nav-compass">
            <Compass />
          </div>
          <div className="floor-view__nav-buttons">
            <FloorNav floorNumber={floorNumber} flatManager={flatManager} />
          </div>
          <div className="floor-view__nav-mini">
            <FloorMini floorNumber={floorNumber} />
          </div>
        </div>
      </div>
    </div>
  )
}
