/**
 * @description The App component.
 */
import React, { useEffect } from 'react'
import debounce from 'debounce'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import * as SettingsDuck from './ducks/settings.duck'
import { appConf } from './config'
import { routesRender } from './routesToRender'
import { makeMods, mergeMods } from '@creatorum/bem'
import { useCurrentPage } from './hooks/useCurrentPage'
import { Page404 } from './pages/Page404'
import { Header } from '@/components/Header'
import { Menu } from '@/components/Menu'
import { useServerSettings } from '@/hooks/useServerSettings'
import { useMenuState } from '@/hooks/useMenuState'
import { useDefaultLocaleRedirect } from '@/hooks/useDefaultLocaleRedirect'

export const App: React.FC = function () {
  const dispatch = useDispatch()
  const { page } = useCurrentPage()
  const [isMenuOpen] = useMenuState()
  const settings = useServerSettings()
  useDefaultLocaleRedirect()

  useEffect(() => {
    let handleResize = () => {
      const w = window.innerWidth
      const h = window.innerHeight
      const prop = w / h
      dispatch(
        SettingsDuck.set({
          windowWidth: w,
          windowHeight: h,
          windowProp: prop,
          isMobile: w <= appConf.responsive.mobile,
        }),
      )
    }
    handleResize()
    handleResize = debounce(handleResize, 700)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dispatch])

  const mods = mergeMods(
    {
      'menu-open': isMenuOpen,
    },
    page?.mods || [],
  )

  return (
    <div className={makeMods('ws', mods)}>
      {settings.isLoading ? ( // todo add loader
        <div className="ws__loader">Loading...</div>
      ) : (
        <div className="ws__cont">
          <div className="ws__page">
            <Switch>
              {routesRender.map((c) => (
                <c.routeComponent
                  key={c.id}
                  path={c.path}
                  exact={c.exact}
                  component={c.contentComponent}
                />
              ))}
              <Route path="*" exact component={Page404} />
            </Switch>
          </div>

          <div className="ws__menu">
            <Menu />
          </div>

          <div className="ws__header">
            <Header />
          </div>
        </div>
      )}
    </div>
  )
}
