/**
 * @description The HomePage component.
 */
import React from 'react'
import { Home } from '@/features/info/components/Home/Home'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'

export const HomePage: React.FC = function (props) {
  const locale = useLocale()

  return <Home key={locale} />
}
