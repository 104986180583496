import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { appConf } from '@/config'

export function useDefaultLocaleRedirect() {
  const locale = useLocale()
  const history = useHistory()

  useEffect(() => {
    if (!locale) {
      history.push(`${appConf.ml.defaultLocale}/`)
    }
  }, [history, locale])
}
