/**
 * @description The FloorMini component.
 */
import React from 'react'
import { floorPlansConf } from '@/features/flats/floor-plans/conf'
import { useTranslate } from '@/hooks/useTranslate'
import { ss } from '@/features/flats/stringSource'
import './FloorMini.scss'

type Props = {
  floorNumber: number
  flatType?: string
}

export const FloorMini: React.FC<Props> = function (props) {
  const { floorNumber, flatType } = props
  const { bgSrc, selections } = floorPlansConf[floorNumber]
  const t = useTranslate()

  const renderFlatBase = (sc: typeof selections[0]) => {
    if (flatType !== sc.flatType) {
      return null
    }

    return (
      <g key={sc.flatType}>
        <path d={sc.path} fill="#D06300" opacity={0.2} />
      </g>
    )
  }

  return (
    <div className="floor-mini">
      <div className="floor-mini__cont">
        <div className="floor-mini__street floor-mini__street--one">
          {t(ss.flats.floor.mini.street1)}
        </div>
        <div className="floor-mini__street floor-mini__street--two">
          {t(ss.flats.floor.mini.street2)}
        </div>
        <div className="floor-mini__plan">
          <svg
            className="floor-mini__svg"
            width={760}
            height={760}
            viewBox="0 0 760 760"
          >
            <image width={760} height={760} x={0} y={0} xlinkHref={bgSrc} />
            <g>{selections.map((sc) => renderFlatBase(sc))}</g>
          </svg>
        </div>
      </div>
    </div>
  )
}
