import { useQuery } from 'react-query'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { getDocuments } from '@/features/posts/api/DocumentsAPI'

export function useDocuments() {
  const locale = useLocale()
  return useQuery(['docs', locale], async () => {
    const res = await getDocuments()
    return res.data
  })
}
