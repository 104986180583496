/**
 * @description The Placement component.
 */
import React, { useState, useEffect, useRef } from 'react'
import {
  PerspectiveCamera,
  WebGLRenderer,
  Scene,
  Color,
  PCFSoftShadowMap,
  Object3D,
  Mesh,
  Raycaster,
  Vector2,
  MeshPhongMaterial,
  Intersection,
  GammaEncoding,
  Material,
} from 'three'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import './Placement.scss'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'

type TourMarker = {
  threeUUID: string
  id: number
  type: '360_ch' | '360'
  mesh: Mesh
}

type ThreeInterface = {
  camera: PerspectiveCamera
  renderer: WebGLRenderer
  labelsRenderer: CSS2DRenderer
  scene: Scene
  controls: OrbitControls
  canvas: HTMLCanvasElement
  raycaster: Raycaster
  mouseVector: Vector2
  materials: {
    tourBase: Material
    tourHover: Material
  }
  markers: TourMarker[]
}

type Props = {
  isInit: boolean
  onInit: (is: boolean) => any
  model: Object3D
}

export const Placement: React.FC<Props> = function (props) {
  const [isModeEnv, setIsEnv] = useState(true)
  const { onInit, model } = props
  const rendererRef = useRef<HTMLDivElement>(null)
  const labelsRendererRef = useRef<HTMLDivElement>(null)
  const animationFrame = useRef(0)
  const threeRef = useRef<ThreeInterface>()
  const propsRef = useRef(props)
  const [hover, setHover] = useState<string | null>(null)
  const stateRef = useRef({
    isModeEnv,
    setIsEnv,
    hover,
    setHover,
  })
  const goTo = useGoToWithLocale()

  useEffect(() => {
    propsRef.current = props
  }, [props])

  useEffect(() => {
    stateRef.current = {
      isModeEnv,
      setIsEnv,
      hover,
      setHover,
    }
  }, [isModeEnv, setIsEnv, hover, setHover])

  useEffect(() => {
    // camera conf
    const camera = new PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      2000,
    )

    // renderer conf
    const renderer = new WebGLRenderer({
      antialias: true,
    })
    const labelsRenderer = new CSS2DRenderer()
    renderer.outputEncoding = GammaEncoding
    renderer.shadowMap.enabled = true
    renderer.shadowMap.type = PCFSoftShadowMap

    // scene conf
    const scene = new Scene()
    scene.background = new Color('#cecece')
    scene.add(camera)
    scene.add(...model.children.filter((el) => el.type !== 'PerspectiveCamera'))

    // init renderer
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.setPixelRatio(window.devicePixelRatio)
    labelsRenderer.setSize(window.innerWidth, window.innerHeight)
    ;(labelsRendererRef.current as HTMLElement).appendChild(
      labelsRenderer.domElement,
    )
    const canvas = (rendererRef.current as HTMLElement).appendChild(
      renderer.domElement,
    )

    // extract markers
    const markersSrc = scene.children.find(
      (el) => el.name === 'metki' && el.type === 'Group',
    )?.children as Mesh[]
    const markers = markersSrc
      .map((mesh) => {
        const { uuid, name } = mesh
        const type = name.startsWith('360_CH2_') ? '360_ch' : '360'
        // if (type === '360') {
        //   mesh.visible = false
        //   return null
        // }
        const id =
          parseInt(name.replace('360_CH2_', '').replace('360_', ''), 10) +
          (type === '360_ch' ? 12 : 0)
        const marker = {
          threeUUID: uuid,
          id,
          type,
          mesh,
        }
        // eslint-disable-next-line no-param-reassign
        mesh.userData.marker = marker
        return marker as TourMarker
      })
      .filter((m) => m !== null)

    // controls conf
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.maxPolarAngle = Math.PI * 0.4
    controls.minDistance = 12
    controls.maxDistance = 40
    controls.enablePan = false

    // extract materials
    const markerBaseMaterial = (
      markers[0].mesh.material as MeshPhongMaterial
    ).clone()
    const markerHoverMaterial = (
      markers[0].mesh.material as MeshPhongMaterial
    ).clone()
    markerHoverMaterial.color.setHex(0xa03033)
    markerHoverMaterial.emissive.setHex(0xa03033)

    // mouse events
    const mouseVector = new Vector2(
      window.innerWidth / 2,
      window.innerHeight / 2,
    )
    const raycaster = new Raycaster()
    const hoverObjects = markers.map((m) => m.mesh)

    const handleHover = (intersects: Intersection[]) => {
      if (!intersects.length) {
        stateRef.current.setHover(null)
        return
      }
      const marker = (intersects[0].object as any).userData.marker as TourMarker
      if (marker) {
        stateRef.current.setHover(marker.threeUUID)
      } else {
        stateRef.current.setHover(null)
      }
    }

    const handleClick = () => {
      raycaster.setFromCamera(mouseVector, camera)
      const intersects = raycaster.intersectObjects(hoverObjects)
      if (!intersects.length) {
        return
      }
      const marker = (intersects[0].object as any).userData.marker as TourMarker
      if (marker) {
        goTo(`/tour/?start=${marker.id}`)
      }
    }
    window.addEventListener('click', handleClick, false)

    // set camera pos
    camera.position.set(
      16.317592974770676 - 5,
      13.2243354876804,
      -15.04442943993347,
    )
    camera.rotation.set(-180, 45, -180)
    camera.frustumCulled = true
    controls.target.set(2.9 - 0.5 - 7, 7, -2.1 - 0.2)
    controls.update()

    // bind refs
    threeRef.current = {
      camera,
      controls,
      scene,
      renderer,
      canvas,
      markers,
      labelsRenderer,
      mouseVector,
      raycaster,
      materials: {
        tourBase: markerBaseMaterial,
        tourHover: markerHoverMaterial,
      },
    }
    // run animation cycle
    const animate = () => {
      animationFrame.current = requestAnimationFrame(animate)
      raycaster.setFromCamera(mouseVector, camera)
      const intersects = raycaster.intersectObjects(hoverObjects)
      handleHover(intersects)
      controls.update()
      renderer.render(scene, camera)
      labelsRenderer.render(scene, camera)
    }
    animate()

    // add resize handler
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
      labelsRenderer.setSize(window.innerWidth, window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    // add mousemove handler
    const handleMouseMove = (e: MouseEvent) => {
      mouseVector.x = (e.clientX / window.innerWidth) * 2 - 1
      mouseVector.y = -(e.clientY / window.innerHeight) * 2 + 1
    }
    window.addEventListener('mousemove', handleMouseMove, false)

    // set is init
    onInit(true)

    // reset
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('click', handleClick)
      cancelAnimationFrame(animationFrame.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!threeRef.current) return
    const { materials, markers } = threeRef.current

    markers.forEach((m) => {
      if (m.threeUUID === hover) {
        m.mesh.material = materials.tourHover
      } else {
        m.mesh.material = materials.tourBase
      }
    })
    ;(document.body.style.cursor as any) = hover !== null ? 'pointer' : null
    // eslint-disable-next-line consistent-return
    return () => {
      ;(document.body.style.cursor as any) = null
    }
  }, [hover])

  return (
    <div className="tour-scene">
      <div className="tour-scene__cont">
        <div ref={rendererRef} className="tour-scene__renderer" />
        <div
          ref={labelsRendererRef}
          className="tour-scene__renderer tour-scene__renderer--labels"
        />
      </div>
    </div>
  )
}
