import { useStringStorage } from '@creatorum/react-ml/dist/hooks/useStringStorage'
import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { useCallback } from 'react'

export function useTranslate() {
  const storage = useStringStorage()
  const locale = useLocale()

  ;(window as any).storage = storage

  return useCallback(
    (path: string, params: Record<string, string | number> = {}) =>
      storage.get(path, {
        locale,
        params,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storage, locale],
  )
}
