import { IStringSource, makeMap } from '@creatorum/react-ml/dist/StringSource'
import { featureConf } from './config'

const strings = {
  [featureConf.name]: {
    // WARNING! Do not change the scope name!
    apartments: {
      floorPanel: {
        floor: '{{ floor }} Этаж',
        flatsAvailable: '{{ flats }} квартир',
        officesAvailable: '{{ flats }} помещений',
        go: 'Перейти',
      },
      text: 'Выберите этаж, чтобы выбрать квартиру',
      promoPanel: {
        title: 'Квартири у 1 черзі',
        button: 'Перейти',
        link: 'https://marshall.daytona.com.ua/#/uk/news/9-%D0%BA%D0%B2%D0%B0%D1%80%D1%82%D0%B8%D1%80-%D0%B2-i-%D0%BE%D1%87%D0%B5%D1%80%D0%B5%D0%B4%D0%B8-%D0%BF%D0%BE-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B5-%D0%BA%D1%80%D0%B5%D0%B4%D0%B8%D1%82%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D1%94%D0%BE/',
      },
    },
    floor: {
      nav: {
        label: 'этаж',
      },
      legend: {
        sold: 'Не в продаже',
        available: 'Свободно',
      },
      mini: {
        street1: 'вул. Набережна Перемоги',
        street2: 'Бульвар Слави',
      },
    },
    flat: {
      type: 'Тип {{ type }}',
      pdf: 'Скачать PDF',
      roomType: 'Помещение {{ type }}',
      status: {
        sold: 'Продано',
      },
      priceCurrencyExchangeNotice:
        'Стоимость 1м<sup>2</sup> указана в долларах США. Стоимость в гривнах по Договору купли-продажи дериватива определяется по курсу НБУ на день оплаты. Стоимость в гривнах по Договору купли-продажи имущественных прав определяется по среднему коммерческому курсу на день оплаты.',
      panel: {
        bedrooms: 'Спален',
        rooms: 'Комнат',
        area: 'Площадь',
        areaView: '{{ area }} м<sup>2</sup>',
        number: '№ квартиры',
        numberOfRoom: '№ помещения',
        loggia: 'Лоджия',
        terrace: 'Терраса',
        priceHidden: 'Цена по запросу',
        floor: 'Этаж',
        type: 'Тип квартиры',
      },
    },
  },
}

export const stringSource: IStringSource & typeof strings = strings

export const stringMap: typeof strings = makeMap(strings) as typeof strings
export const ss = stringMap

export default strings
