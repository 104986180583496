import {
  makeRoutesRenderConf,
  RenderMap,
} from '@creatorum/react-router-helper/dist/route'
import { keys, routes } from './routes'
import { DocumentsPage } from './pages/DocumentsPage'
import { HowToBuyPage } from './pages/HowToBuyPage'
import { NewsPage } from './pages/NewsPage'
import { NewsItemPage } from './pages/NewsItemPage'
import { ConstructionPage } from './pages/ConstructionPage'
import { ConstructionItemPage } from './pages/ConstructionItemPage'
import { GalleryPage } from './pages/GalleryPage'

const renderMap: RenderMap = {
  [keys.docs]: { contentComponent: DocumentsPage },
  [keys.htb]: { contentComponent: HowToBuyPage },
  [keys.news]: { contentComponent: NewsPage },
  [keys.newsItem]: { contentComponent: NewsItemPage },
  [keys.construction]: { contentComponent: ConstructionPage },
  [keys.constructionItem]: { contentComponent: ConstructionItemPage },
  [keys.gallery]: { contentComponent: GalleryPage },
}

export const routesRender = makeRoutesRenderConf(renderMap, routes)
