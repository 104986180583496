import { useLocale } from '@creatorum/react-ml/dist/hooks/useLocale'
import { useQuery } from 'react-query'
import { get } from '@/api/SettingsAPI'

export function useServerSettings() {
  const locale = useLocale()

  const { data, error, isLoading } = useQuery(
    ['settings', locale],
    async () => {
      const res = await get(locale as string)
      return res.data
    },
  )
  return {
    isLoading,
    error,
    ...data,
  }
}
