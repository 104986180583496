import rawConf1 from './1.json'
import rawConf2 from './2.json'
import rawConf3 from './3-13.json'
import rawConf14 from './14-19,22.json'
import rawConf20 from './20.json'
import rawConf21 from './21.json'
import rawConf23 from './23.json'
import rawConf24 from './24.json'

import bg1 from './1.png'
import bg2 from './2.png'
import bg3 from './3-13.png'
import bg14 from './14-19,22.png'
import bg20 from './20.png'
import bg21 from './21.png'
import bg23 from './23.png'
import bg24 from './24.png'

const conf1 = {
  bgSrc: bg1,
  selections: rawConf1,
}
const conf2 = {
  bgSrc: bg2,
  selections: rawConf2,
}
const conf3 = {
  bgSrc: bg3,
  selections: rawConf3,
}
const conf14 = {
  bgSrc: bg14,
  selections: rawConf14,
}
const conf20 = {
  bgSrc: bg20,
  selections: rawConf20,
}
const conf21 = {
  bgSrc: bg21,
  selections: rawConf21,
}
const conf23 = {
  bgSrc: bg23,
  selections: rawConf23,
}
const conf24 = {
  bgSrc: bg24,
  selections: rawConf24,
}

type FloorPlanConf = typeof conf2

export const floorPlansConf: Record<string, FloorPlanConf> = {
  '1': conf1,
  '2': conf2,
  '3': conf3,
  '4': conf3,
  '5': conf3,
  '6': conf3,
  '7': conf3,
  '8': conf3,
  '9': conf3,
  '10': conf3,
  '11': conf3,
  '12': conf3,
  '13': conf3,
  '14': conf14,
  '15': conf14,
  '16': conf14,
  '17': conf14,
  '18': conf14,
  '19': conf14,
  '20': conf20,
  '21': conf21,
  '22': conf14,
  '23': conf23,
  '24': conf24,
}

export function getFlatTypesList() {
  return Object.keys(floorPlansConf).reduce((acc: string[], floorNumber) => {
    const floor = floorPlansConf[floorNumber as unknown as number]
    // eslint-disable-next-line no-param-reassign
    acc = [...acc, ...floor.selections.map((s) => s.flatType)]
    return acc
  }, [])
}
