import { Config } from '@creatorum/react-router-helper/dist/route'
import { featureConf } from './config'

const NAMESPACE = featureConf.name

export const keys = {
  home: `${NAMESPACE}/home`,
  chars: `${NAMESPACE}/chars`,
  team: `${NAMESPACE}/team`,
  contacts: `${NAMESPACE}/contacts`,
  placement: `${NAMESPACE}/placement`,
  window: `${NAMESPACE}/window`,
  tour: `${NAMESPACE}/tour`,
  info: `${NAMESPACE}/info`,
}

export const routes = [
  new Config({
    id: keys.home,
    path: '/',
  }),
  new Config({
    id: keys.chars,
    path: '/characteristics',
  }),
  new Config({
    id: keys.team,
    path: '/team',
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.contacts,
    path: '/contacts',
    mods: ['header-dark'],
  }),
  new Config({
    id: keys.placement,
    path: '/placement',
  }),
  new Config({
    id: keys.window,
    path: '/window/:floor',
  }),
  new Config({
    id: keys.tour,
    path: '/tour',
  }),
  new Config({
    id: keys.info,
    path: '/info',
    mods: ['header-dark'],
  }),
]
