import { useParams } from 'react-router'
import { useMemo } from 'react'

export interface IFloorFlatParams {
  floorNumber: number | null
  flatID: number | null
}

export function useFloorFlatParams(): IFloorFlatParams {
  const params = useParams<{ flat?: string; floor?: string }>()
  // eslint-disable-next-line no-nested-ternary
  const flat = params.flat
    ? params.flat === 'none'
      ? null
      : parseInt(params.flat, 10)
    : null
  const floor = params.floor ? parseInt(params.floor, 10) : null
  return useMemo(
    () => ({
      floorNumber: Number.isNaN(floor as number) ? null : floor,
      flatID: Number.isNaN(flat as number) ? null : flat,
    }),
    [flat, floor],
  )
}
