import { IStringSource, makeMap } from '@creatorum/react-ml/dist/StringSource'
import { featureConf } from './config'

const strings = {
  [featureConf.name]: {
    // WARNING! Do not change the scope name!
    home: {
      start: 'Начать прогулку',
      hotspots: {
        tour: '3D Тур',
        highlights: 'Особенности',
        chars: 'Характеристики',
        flats: 'Выбрать квартиру',
        flatsCount: '{{ flats }} квартир',
      },
    },
    team: {
      title: 'Команда',
    },
    contacts: {
      title: 'Контакты',
      subtitle: 'Офис продаж',
      markers: {
        main: 'ЖК Marshall',
        office: 'Офис продаж',
      },
      form: {
        title: 'Связаться с нами',
        description: 'Оставьте свои данные и мы обязательно свяжемся с вами',
      },
    },
    info: {
      title: 'Важная информация',
      content:
        '<p>Звертаємо вашу увагу, що інформація представлена на сайті, носить лише інформаційно-рекламний характер, не є офертою, публічною офертою відповідно до статті 641 Цивільного Кодексу України.</p><p>Розміщені на сайті планування квартир та інших приміщень не є точними копіями проектної документації або документів технічної інвентаризації і виконані в рекламних цілях для наочного уявлення характеристик квартир і приміщень.</p><p>Елементи благоустрою, матеріали і кольори оздоблення фасаду, місць загального користування, квартир та інших приміщень у комплексі, що будується, можуть відрізнятися від наведених на сайті.</p><p>Для отримання актуальної інформації щодо умов продажу, а також характеристики квартир, нежитлових приміщень та місць загального користування Ви можете звернутися до відділу продажу забудовника.</p>',
    },
  },
}

export const stringSource: IStringSource & typeof strings = strings

export const stringMap: typeof strings = makeMap(strings) as typeof strings
export const ss = stringMap

export default strings
