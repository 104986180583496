/**
 * @description The BackButton component.
 */
import React from 'react'
import { ReactComponent as BackIcon } from '@/features/posts/components/NewsItem/i/back.svg'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/stringSources'
import { useGoBack } from '@/hooks/useGoBack'
import './BackButton.scss'

type Props = {
  defaultTo?: string
}

export const BackButton: React.FC<Props> = function (props) {
  const goBack = useGoBack()
  return (
    <button
      type="button"
      className="back"
      onClick={() => goBack(props.defaultTo || '/')}
    >
      <i>
        <BackIcon />
      </i>
      <T p={ss.shared.back} />
    </button>
  )
}
