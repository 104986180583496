/**
 * @module ducks/settings.duck
 * @description The settings duck.
 */

import { SimpleDuck } from '@creatorum/react-duck/dist/core/duckStack'
import { StoreState } from '@creatorum/react-duck/dist/core/reduxStack'
import * as StateExtension from '@creatorum/react-duck/dist/StateExtension/StateExtension'
import { LOCATION_CHANGE } from 'connected-react-router'
import { all, put, takeEvery, call } from 'redux-saga/effects'

/** Duck namespace */
const NAMESPACE = 'settings'

/** Use state extension */
const stateExt = new StateExtension.StateExtensionBuilder(NAMESPACE).make()

/**
 * Action types
 */
export type ActionType = typeof stateExt.actionTypes

/**
 * Actions
 */
export type Action = StateExtension.ExtAction

/**
 * State
 */
interface State {
  windowWidth: number
  windowHeight: number
  windowProp: number
  isMobile: boolean
  isMenuOpen: boolean
}

const initialState: State = {
  isMobile: false,
  windowHeight: 0,
  windowProp: 0,
  windowWidth: 0,
  isMenuOpen: false,
}

/**
 * Reducer
 */
function reducer(state = initialState, action: Action) {
  switch (action.type) {
    default:
      return stateExt.reducer(state, action) as State
  }
}

/**
 * Action creators
 */
export const { set } = stateExt.actionCreators
export const setIsOpenMenu = (isOpen: boolean) => set({ isMenuOpen: isOpen })

/**
 * Selectors
 */
export const { selectField } = stateExt.selectors
export const selectIsMobile = (s: StoreState): boolean =>
  selectField(s, 'isMobile')
export const selectIsMenuOpen = (s: StoreState): boolean =>
  selectField(s, 'isMenuOpen')
export const selectAll = (s: StoreState) => s[NAMESPACE] as State

/**
 * Sagas
 */
function* locationChangeSaga() {
  yield put(setIsOpenMenu(false))
  yield call(function () {
    ;(document.querySelector('html') as HTMLHtmlElement).scrollTop = 0
  })
}

/**
 * Object to connect
 */
const connectable: SimpleDuck = {
  namespace: NAMESPACE,
  reducer,
  sagas: [
    function* () {
      yield all([takeEvery(LOCATION_CHANGE, locationChangeSaga)])
    },
  ],
}
export default connectable
