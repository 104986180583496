import { api } from '@/services/api'
import { appConf } from '@/config'

export type CharsItem = {
  id: number
  title: string
  content: string
  photo: string
  photoMobile?: string
}

export function getChars(isMobile = false) {
  return api.get<CharsItem[]>(
    '/characteristics/',
    {},
    {
      transformResponse: (raw) => {
        const data = JSON.parse(raw)
        return data.characteristics.map((el: any) => ({
          id: el.id,
          title: el.title,
          content: el.content,
          photo: appConf.api.siteURL + el.photo, // todo remove prefix
          photoMobile: appConf.api.siteURL + (el.mobile_photo || el.photo), // todo remove prefix
        }))
      },
    },
    {
      i18n: true,
      getPreloadingImages: (data: CharsItem[]) =>
        data
          .slice(0, 3)
          .map((el) => (isMobile ? el.photoMobile || el.photo : el.photo)),
    },
  )
}
