import {
  makeRoutesRenderConf,
  RenderMap,
} from '@creatorum/react-router-helper/dist/route'
import { keys, routes } from './routes'
import { ApartmentsPage } from './pages/ApartmentsPage'
import { FloorPage } from './pages/FloorPage'
import { FlatPage } from './pages/FlatPage'

const renderMap: RenderMap = {
  [keys.apartments]: { contentComponent: ApartmentsPage },
  [keys.floor]: { contentComponent: FloorPage },
  [keys.flat]: { contentComponent: FlatPage },
}

export const routesRender = makeRoutesRenderConf(renderMap, routes)
