/**
 * @description The PageNotFound component.
 */
import React from 'react'
import './PageNotFound.scss'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/stringSources'
import { Button } from '@/components/Button'
import { ReactComponent as GoIcon } from './i/go.svg'

export const PageNotFound: React.FC = function (props) {
  return (
    <div className="p404">
      <div className="p404__cont">
        <h1 className="p404__title">
          <T p={ss.shared.p404.title} />
        </h1>
        <div className="p404__subtitle">
          <T p={ss.shared.p404.subtitle} />
        </div>
        <div className="p404__text">
          <T p={ss.shared.p404.text} />
        </div>
        <div className="p404__button">
          <Button icon={<GoIcon />} as="routerLink" to="/">
            <T p={ss.shared.p404.button} />
          </Button>
        </div>
      </div>
    </div>
  )
}
