/* eslint-disable camelcase */

import { api } from '@/services/api'
import { appConf } from '@/config'

export type Team = {
  id: number
  logo: string
  title: string
  content: string
  site: string
  site_title: string
}

export function getTeamsList() {
  return api.get(
    '/team/',
    {},
    {
      transformResponse: (raw) => {
        const data = JSON.parse(raw) as {
          team: Team[]
        }
        return data.team.map((t) => ({
          ...t,
          logo: appConf.api.siteURL + t.logo,
        }))
      },
    },
    {
      i18n: true,
    },
  )
}
