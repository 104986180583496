/**
 * @description The HowToBuy component.
 */
import React from 'react'
import { HowToBuy as Data } from '@/features/posts/api/HowToBuyAPI'
import { HowToBuyOptions } from './HowToBuyOptions'
import { HowToBuyOrder } from './HowToBuyOrder'
import { HowToBuyInfo } from './HowToBuyInfo'
import './HowToBuy.scss'

type Props = {
  data: Data
}

export const HowToBuy: React.FC<Props> = function (props) {
  const {
    data: {
      blocks: { options, order, info },
    },
  } = props

  return (
    <div className="htb">
      <div className="htb__cont">
        <div className="htb__options">
          <HowToBuyOptions
            blockItems={options.block_items}
            title={options.title}
          />
        </div>

        <div className="htb__order">
          <HowToBuyOrder blockItems={order.block_items} title={order.title} />
        </div>

        <div className="htb__info">
          <HowToBuyInfo blockItems={info.block_items} />
        </div>
      </div>
    </div>
  )
}
