/* eslint-disable camelcase */

import { api } from '@/services/api'

export type ConstructionListItem = {
  id: number
  slug: string
  title: string
  photo: string
  youtube_video_url: string | null
  mobile_photo: string | null
  date_created: string
}

export type ConstructionItem = ConstructionListItem & {
  gallery_photos: ConstructionGalleyPhoto[]
}

export type ConstructionGalleyPhoto = {
  photo: string
  mobile_photo: string | null
  alt: string
  date_created: string
}

export function getConstructionList() {
  return api.get<ConstructionListItem[]>(
    '/construction-dynamic/',
    {},
    {},
    {
      i18n: true,
    },
  )
}

export function getConstructionItem(slug: string) {
  return api.get<ConstructionItem>(
    `/construction-dynamic/${slug}/`,
    {},
    {},
    {
      i18n: true,
    },
  )
}
