/**
 * @description The HowToBuyInfo component.
 */
import React from 'react'
import { HowToBuyBlockItem } from '@/features/posts/api/HowToBuyAPI'
import { useIsMobile } from '@/hooks/useIsMobile'
import bgImg from './i/info-bg.jpg'
import bgMobileImg from './i/info-bg-m.jpg'

type Props = {
  blockItems: HowToBuyBlockItem[]
}

export const HowToBuyInfo: React.FC<Props> = function (props) {
  const isMobile = useIsMobile()
  const bg = isMobile ? bgMobileImg : bgImg
  const items = props.blockItems.slice(0, 2)

  return (
    <div className="htb-info">
      <div className="htb-info__cont">
        <img src={bg} alt="" className="htb-info__image" />
        <div className="htb-info__content">
          {items.map((bi, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="htb-info__item" key={i}>
              {bi.title && <h3 className="htb-info__item-title">{bi.title}</h3>}
              {bi.content && (
                <div
                  className="htb-info__item-content light-content-area"
                  dangerouslySetInnerHTML={{ __html: bi.content }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
