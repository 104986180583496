/**
 * @description The PlacementPage component.
 */
import React, { useState } from 'react'
import { Placement } from '@/features/info/components/Placement'
import { PageLayout } from '@/components/PageLayout'
import { useLoadThreeJSModel } from '@/hooks/useLoadThreeJSModel'
import { appConf } from '@/config'
import { useIsMobile } from '@/hooks/useIsMobile'

export const PlacementPage: React.FC = function (props) {
  const { isLoading, isError, model } = useLoadThreeJSModel(
    `${appConf.scenesURL}/main/scene2.json`,
  )
  const isMobile = useIsMobile()
  const [isModelInit, setIsModelInit] = useState(false)

  return (
    <PageLayout
      fullpage
      footer={!isMobile}
      isLoading={isLoading}
      isError={isError}
      footerProps={{
        variant: 'light',
      }}
    >
      {model && (
        <Placement isInit={isModelInit} onInit={setIsModelInit} model={model} />
      )}
    </PageLayout>
  )
}
