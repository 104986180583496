/**
 * Feature ducks
 */

import { SimpleDuck } from '@creatorum/react-duck/dist/core/duckStack'
import { connectable as example } from './ducks/example.duck'

/**
 * Add ducks to array here
 */
export const ducks: SimpleDuck[] = [example]
