/**
 * @description The Contacts component.
 */
import React, { useMemo, useState } from 'react'
import './Contacts.scss'
import { useServerSettings } from '@/hooks/useServerSettings'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ss } from '@/features/info/stringSource'
import { ReactComponent as IconEmail } from './i/email.svg'
import { ReactComponent as IconPhone } from './i/phone.svg'
import { ReactComponent as IconMarker } from './i/marker.svg'
import { ReactComponent as IconTime } from './i/time.svg'
import { GoogleMap } from '@/components/GoogleMap'
import { CONTACTS_MAP_MARKER } from '@/googleMapConfig'
import { GoogleMapMarker } from '@/types'
import mainIcon from '@/components/GoogleMap/i/mainMarkerIcon.svg'
import officeIcon from '@/components/GoogleMap/i/officeMarkerIcon.svg'
import { useLocaleString } from '@creatorum/react-ml/dist/hooks/useLocaleString'
import { FeedbackForm } from '@/components/FeedbackForm/FeedbackForm'
import { makeMods } from '@creatorum/bem'
import { ReactComponent as SidebarButton } from './i/sidebar.svg'
import { ReactComponent as CallIcon } from './i/call.svg'
import { ReactComponent as CloseIcon } from './i/close.svg'
import { ReactComponent as CloseModalIcon } from './i/close-modal.svg'

export const Contacts: React.FC = function (props) {
  const settings = useServerSettings()
  const mainMarkerLabel = useLocaleString(ss.info.contacts.markers.main)
  const officeMarkerLabel = useLocaleString(ss.info.contacts.markers.office)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const markers: GoogleMapMarker[] = useMemo(
    () => [
      {
        id: 'main',
        type: 'main',
        label: mainMarkerLabel,
        point: {
          lat: 48.41601169927436,
          lng: 35.069679352899875,
        },
        icon: mainIcon,
      },
      {
        id: 'office',
        type: 'office',
        label: officeMarkerLabel,
        point: {
          lat: 48.457846232666704,
          lng: 35.059713576378,
        },
        icon: officeIcon,
      },
    ],
    [mainMarkerLabel, officeMarkerLabel],
  )

  return (
    <div
      className={makeMods('contacts', {
        open: sidebarOpen,
      })}
    >
      <div className="contacts__cont">
        <div className="contacts__top">
          <h1 className="contacts__title">
            <T p={ss.info.contacts.title} />
          </h1>

          <div className="contacts__panel">
            <h2 className="contacts__panel-title">
              <T p={ss.info.contacts.subtitle} />
            </h2>
            <div className="contacts__list">
              <div className="contacts__el">
                <div className="contacts__i">
                  <IconMarker />
                </div>
                <div className="contacts__text">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={settings.googleMap}
                    dangerouslySetInnerHTML={{
                      __html: settings.salesOfficeAddress || '',
                    }}
                  />
                </div>
              </div>
              <div className="contacts__el">
                <div className="contacts__i">
                  <IconTime />
                </div>
                <div
                  className="contacts__text"
                  dangerouslySetInnerHTML={{
                    __html: settings.workingTime || '',
                  }}
                />
              </div>
              <div className="contacts__el">
                <div className="contacts__i">
                  <IconPhone />
                </div>
                <div className="contacts__text">
                  <a
                    href={`tel:${settings.phone}`}
                    dangerouslySetInnerHTML={{
                      __html: settings.phone || '',
                    }}
                  />
                </div>
              </div>
              <div className="contacts__el">
                <div className="contacts__i">
                  <IconEmail />
                </div>
                <div className="contacts__text">
                  <a
                    href={`mailto:${settings.email}`}
                    dangerouslySetInnerHTML={{
                      __html: settings.email || '',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts__bottom">
          <div className="contacts__map">
            <GoogleMap
              markers={markers}
              center={CONTACTS_MAP_MARKER}
              zoom={15}
            />
          </div>
        </div>
      </div>

      {/* todo add background */}
      <div className="contacts__sidebar">
        <button
          type="button"
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="contacts__sidebar-button"
        >
          <SidebarButton />
          <span className="contacts__sidebar-button-over">
            <span className="contacts__sidebar-button-i">
              {sidebarOpen ? <CloseIcon /> : <CallIcon />}
            </span>
          </span>
        </button>
        <div className="contacts__sidebar-cont">
          <div className="contacts__sidebar-wrap">
            <FeedbackForm
              title={<T p={ss.info.contacts.form.title} />}
              description={<T p={ss.info.contacts.form.description} />}
            />
          </div>
        </div>
        <button
          type="button"
          onClick={() => setSidebarOpen(false)}
          className="contacts__sidebar-close"
        >
          <CloseModalIcon />
        </button>
      </div>
    </div>
  )
}
