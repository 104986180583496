/**
 * @description The FlatPanel component.
 */
import React from 'react'
import { IFlat } from '@/features/flats/services/flatManager'
import './FlatPanel.scss'
import { ss } from '@/features/flats/stringSource'
import { T } from '@creatorum/react-ml/dist/components/T'
import { ReactComponent as TrueIcon } from './i/true.svg'
import { ReactComponent as FalseIcon } from './i/false.svg'

type Props = {
  flat: IFlat
  detailed?: boolean
}

export const FlatPanel: React.FC<Props> = function (props) {
  const { flat, detailed } = props
  const {
    bedrooms,
    area,
    number,
    loggia,
    terrace,
    priceString,
    isHidePrice,
    isLiving,
    floorNumber,
    planCode,
  } = flat

  return (
    <div className="flat-panel">
      <div className="flat-panel__cont">
        <div className="flat-panel__row">
          <div className="flat-panel__label">
            <T
              p={
                isLiving
                  ? ss.flats.flat.panel.bedrooms
                  : ss.flats.flat.panel.rooms
              }
            />
          </div>
          <div className="flat-panel__value">{bedrooms}</div>
        </div>
        <div className="flat-panel__row">
          <div className="flat-panel__label">
            <T p={ss.flats.flat.panel.area} />
          </div>
          <div className="flat-panel__value">
            <T html p={ss.flats.flat.panel.areaView} params={{ area }} />
          </div>
        </div>
        {detailed && (
          <div className="flat-panel__row">
            <div className="flat-panel__label">
              <T p={ss.flats.flat.panel.floor} />
            </div>
            <div className="flat-panel__value">{floorNumber}</div>
          </div>
        )}
        <div className="flat-panel__row">
          <div className="flat-panel__label">
            <T
              p={
                isLiving
                  ? ss.flats.flat.panel.number
                  : ss.flats.flat.panel.numberOfRoom
              }
            />
          </div>
          <div className="flat-panel__value">{number}</div>
        </div>
        {detailed && (
          <div className="flat-panel__row">
            <div className="flat-panel__label">
              <T p={ss.flats.flat.panel.type} />
            </div>
            <div className="flat-panel__value">{planCode}</div>
          </div>
        )}
        {isLiving && (
          <>
            <div className="flat-panel__row">
              <div className="flat-panel__label">
                <T p={ss.flats.flat.panel.loggia} />
              </div>
              <div className="flat-panel__value">
                {loggia ? <TrueIcon /> : <FalseIcon />}
              </div>
            </div>
            <div className="flat-panel__row">
              <div className="flat-panel__label">
                <T p={ss.flats.flat.panel.terrace} />
              </div>
              <div className="flat-panel__value">
                {terrace ? <TrueIcon /> : <FalseIcon />}
              </div>
            </div>
          </>
        )}
        <div className="flat-panel__price">
          {isHidePrice ? (
            <T p={ss.flats.flat.panel.priceHidden} />
          ) : (
            priceString
          )}
        </div>
      </div>
    </div>
  )
}
