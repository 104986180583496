/**
 * @description The DocumentsPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useDocuments } from '@/features/posts/hooks/useDocuments'
import { DocumentsList } from '@/features/posts/components/DocumentsList'
import { ss } from '@/features/posts/stringSource'

export const DocumentsPage: React.FC = function (props) {
  const docs = useDocuments()

  // todo add background
  return (
    <PageLayout
      title={ss.posts.documents.title}
      isLoading={docs.isLoading}
      isError={docs.isError}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <DocumentsList items={docs.data || []} />
    </PageLayout>
  )
}
