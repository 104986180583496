import { selectIsMenuOpen, setIsOpenMenu } from '@/ducks/settings.duck'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback, useMemo } from 'react'

export function useMenuState() {
  const isOpen = useSelector(selectIsMenuOpen)
  const dispatch = useDispatch()
  const setIsOpen = useCallback(
    (...params: Parameters<typeof setIsOpenMenu>) => {
      dispatch(setIsOpenMenu(...params))
    },
    [dispatch],
  )
  const tuple: [typeof isOpen, typeof setIsOpen] = useMemo(
    () => [isOpen, setIsOpen],
    [isOpen, setIsOpen],
  )
  return tuple
}
