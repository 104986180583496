/**
 * @description The NewsItemPage component.
 */
import React from 'react'
import { PageLayout } from '@/components/PageLayout'
import { useNewsItem } from '@/features/posts/hooks/useNewsItem'
import { useParams } from 'react-router'
import { NewsItem as TNewsItem } from '@/features/posts/api/NewsAPI'
import { NewsItem } from '@/features/posts/components/NewsItem'

export const NewsItemPage: React.FC = function () {
  const params: { slug: string } = useParams()
  const newsItemQuery = useNewsItem(params.slug)

  // todo add 404 redirect
  return (
    <PageLayout
      isLoading={newsItemQuery.isLoading}
      isError={newsItemQuery.isError}
      footer={true}
      footerProps={{
        variant: 'dark',
      }}
    >
      <NewsItem data={newsItemQuery.data as TNewsItem} />
    </PageLayout>
  )
}
