/**
 * @description The ContentArea component.
 */
import React, { HTMLAttributes } from 'react'
import './ContentArea.scss'

type Props = HTMLAttributes<HTMLDivElement> & {
  content: string
}

export const ContentArea: React.FC<Props> = function ({
  content,
  children,
  className,
  ...rest
}) {
  return (
    <div
      className={`content-area ${className}`}
      {...rest}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
