/**
 * @description The EmbeddedGallery component.
 */
import React, { useCallback, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import { Keyboard, Navigation, Controller, Swiper as SwiperClass } from 'swiper'
import { useIsMobile } from '@/hooks/useIsMobile'
import { Gallery } from '@/components/Gallery/Gallery'
import { makeMods } from '@creatorum/bem'
import './EmbeddedGallery.scss'

export type EmbeddedGalleryPhoto = {
  alt?: string
  src: string
  srcMobile?: string
}

type Props = {
  photos: EmbeddedGalleryPhoto[]
  modal?: boolean
}

export const EmbeddedGallery: React.FC<Props> = function (props) {
  const { photos, modal = false } = props
  const isMobile = useIsMobile()

  const [embeddedSwiper, setEmbeddedSwiper] = useState<SwiperClass | null>(null)
  const [modalSwiper, setModalSwiper] = useState<SwiperClass | null>(null)

  const [currentIndex, setCurrentIndex] = useState(0)
  const currentToShow = currentIndex + 1
  const currentValue =
    currentToShow < 10 ? `0${currentToShow}` : `${currentToShow}`

  const [modalOpen, setModalOpen] = useState(false)
  const handleOpenModal = useCallback(() => {
    if (modal) {
      setModalOpen(true)
      if (modalSwiper) {
        modalSwiper.slideTo(currentIndex, 0)
      }
    }
  }, [modal, modalSwiper, currentIndex])
  const handleCloseModal = useCallback(() => {
    if (modal) {
      setModalOpen(false)
    }
  }, [modal])

  return (
    <div
      className={makeMods('emg', {
        mo: modalOpen,
      })}
    >
      <div className="emg__cont">
        <div className="emg__slider">
          <Swiper
            slidesPerView={1}
            navigation
            keyboard
            speed={900}
            resistance={true}
            resistanceRatio={0}
            modules={[
              Navigation,
              Keyboard,
              ...(modalSwiper ? [Controller] : []),
            ]}
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
            onSwiper={setEmbeddedSwiper}
            controller={modalSwiper ? { control: modalSwiper } : undefined}
          >
            {photos.map((photo, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <SwiperSlide key={`${photo.src}-${i}`}>
                <div onClick={handleOpenModal} className="emg__slide">
                  <img
                    className="emg__img"
                    src={
                      isMobile && photo.srcMobile ? photo.srcMobile : photo.src
                    }
                    alt={photo.alt}
                  />
                  <div className="emg__slide-over">
                    <i />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="emg__footer">
          <div className="emg__counter">
            <b>{currentValue}</b>/{' '}
            {photos.length < 10 ? `0${photos.length}` : photos.length}
          </div>
        </div>
      </div>

      {modal && (
        <div className="emg__modal">
          <Gallery
            onSwiper={setModalSwiper}
            controlledSwiper={embeddedSwiper}
            photos={photos}
            onClose={handleCloseModal}
            closeButton={true}
          />
        </div>
      )}
    </div>
  )
}
