/**
 * @description The FloorPage component.
 */
import React, { useEffect } from 'react'
import { useIsMobile } from '@/hooks/useIsMobile'
import { PageLayout } from '@/components/PageLayout'
import { FloorView } from '@/features/flats/components/FloorView'
import { useParams } from 'react-router'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'
import { floorPlansConf } from '@/features/flats/floor-plans/conf'
import { useFlatsData } from '@/features/flats/hooks/useFlatsData'
import { FlatManager } from '@/features/flats/services/flatManager'

export const FloorPage: React.FC = function (props) {
  const isMobile = useIsMobile()
  const { floor } = useParams<{ floor: string }>()
  const floorNumber = parseInt(floor, 10)
  const floorConf = floorPlansConf[floorNumber]
  const goTo = useGoToWithLocale()
  const { isLoading, isError, data } = useFlatsData()

  useEffect(() => {
    if (Number.isNaN(floorNumber) || !floorConf) {
      goTo('/apartments')
    }
  }, [floorNumber, goTo, floorConf])

  if (Number.isNaN(floorNumber)) {
    return null
  }

  return (
    <PageLayout
      isLoading={isLoading}
      isError={isError}
      fullpage
      footer={!isMobile}
      footerProps={{ variant: 'dark' }}
    >
      <FloorView floorNumber={floorNumber} flatManager={data as FlatManager} />
    </PageLayout>
  )
}
