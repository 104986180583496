import { useCallback, useEffect, useState } from 'react'
import { useFlatsData } from '@/features/flats/hooks/useFlatsData'
import { useFloorFlatParams } from '@/features/flats/hooks/useFloorFlatParams'
import { useGoToWithLocale } from '@creatorum/react-ml/dist/hooks/useGoToWithLocale'

export interface IUseWrongFlatRedirectOpts {
  to?: string
  checkOnlyIfNotNull?: boolean
}

export function useWrongFlatRedirect({
  to = '/apartments/',
  checkOnlyIfNotNull = false,
}: IUseWrongFlatRedirectOpts = {}) {
  const [canRender, setCanRender] = useState(false)
  const goTo = useGoToWithLocale()
  const redirect = useCallback(() => goTo(to), [goTo, to])
  const params = useFloorFlatParams()
  const { data: flatsData, isLoading } = useFlatsData()

  useEffect(() => {
    if (checkOnlyIfNotNull) {
      if (params.flatID === null) {
        setCanRender(true)
        return
      }
    }

    // eslint-disable-next-line consistent-return
    if (params.flatID === null) return redirect()
    if (flatsData && !isLoading) {
      const flat = flatsData.getFlatByID(params.flatID)
      // eslint-disable-next-line consistent-return
      if (flat === undefined) return redirect()
      // eslint-disable-next-line consistent-return
      if (!flat.isAvailable) return redirect()
    }

    setCanRender(true)
  }, [params, flatsData, isLoading, redirect, checkOnlyIfNotNull])

  return canRender
}
