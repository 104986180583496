/**
 * @description The GoogleMapMarker component.
 */
import React, { useMemo, useRef, useState } from 'react'
import { InfoWindow, Marker } from '@react-google-maps/api'
import { makeMods } from '@creatorum/bem'
import { T } from '@creatorum/react-ml/dist/components/T'
import { GoogleMapMarker as TMarker } from '@/types'
import { useRem } from '@/hooks/useRem'
import mainIcon from './i/mainMarkerIcon.svg'

type Props = {
  icon: TMarker['icon']
  iconSize?: TMarker['iconSize']
  label: string
  lat: number
  lng: number
  isMain?: boolean
}

export const GoogleMapMarker: React.FC<Props> = function (props) {
  const { lat, lng, icon, iconSize, label, isMain } = props
  const { rem } = useRem()
  const markerRef = useRef<{ marker: google.maps.Marker } | null>(null)
  const [markerLoaded, setMarkerLoaded] = useState(false)

  const iconProps = useMemo(
    () => ({
      url: icon,
      ...(isMain
        ? {
            url: mainIcon,
            size: new google.maps.Size(rem(70), rem(77)),
            scaledSize: new google.maps.Size(rem(70), rem(77)),
            anchor: new google.maps.Point(rem(70 / 2), rem(77)),
          }
        : {}),
      ...iconSize,
    }),
    [icon, iconSize, isMain, rem],
  )

  const [isHover, setIsHover] = useState(false)
  const iwProps = useMemo(
    () => ({
      position: {
        lat,
        lng,
      },
    }),
    [lat, lng],
  )

  return (
    <Marker
      position={{
        lat,
        lng,
      }}
      icon={iconProps}
      onLoad={(m) => {
        markerRef.current = {
          marker: m,
        }
        setMarkerLoaded(true)
      }}
    >
      {markerLoaded && markerRef.current !== null && (
        <InfoWindow anchor={markerRef.current.marker}>
          <div
            className={makeMods('gm-marker', {
              hover: isHover,
              main: !!isMain,
            })}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}
          >
            <div className="gm-marker__cont">
              <div className="gm-marker__text ">
                <T p={label} />
              </div>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
}
